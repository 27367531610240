import dayjs from 'dayjs';

//出店条件ページAPI取得
export const storeOpeningConditionsData = (res:any) => {
    const DataObj = {
        settingReason: res[0].setting_reason,                                           //ゾーン設定の理由
        candidateLocationRuins: res[0].candidate_location_ruins,                        //候補地跡地
        candidateLocationRuinsLongText: res[0].candidate_location_ruins_long_text,      //候補地跡地(ロングテキスト)
        isStoreNameSelectReason: res[0].is_store_name_select_reason,                    //所在地表示以外にて選定（選定根拠を下記に記載） チェックボックス
        removalReason: res[0].removal_Reason,                                           //理由
        open24Hours: res[0].open24_hours,                                               //24時間営業 有
        notOpen24Hours: res[0].not_open24_hours,                                        //24時間営業 無
        alcoholLicense: res[0].alcohol_license,                                         //酒免許 有
        noAlcoholLicense: res[0].no_alcohol_license,                                    //酒免許 無
        tobaccoLicence: res[0].tobacco_licence,                                         //煙草免許 有
        noTobaccoLicence: res[0].no_tobacco_licence,                                    //煙草免許 無
        areaType: res[0].area_type,                                                     //地域区分
        nearestAlreadystoreDistance: res[0].nearest_already_store_distance,             //最寄り既設店までの距離
        environmentType: res[0].environment_type,                                       //環境区分 
        acquisitionRequirement: res[0].acquisition_requirement,                         //取得要件
        tobaccoDistanceStandard: res[0].tobacco_distance_standard,                      //煙草距離基準
        distanceStandardAlreadystoreCount: res[0].distance_standard_alreadystore_count, //距離基準内の既設店舗数
        previousJob: res[0].previous_job,                                               //前職
        conflictCVS: res[0].conflict_cvs,                                               //元競合CVS
        cvsLastNippan: res[0].cvs_last_nippan,                                          //CVS直近日販
        relocationDistance: res[0].relocation_distance,                                 //移転
        ownershipType: res[0].ownership_type,                                           //所有形態​
        storeType: res[0].store_type,                                                   //店舗形態
        storefrontage: res[0].store_frontage,                                           //店舗間口
        storeArea: res[0].store_area,                                                   //店舗面積
        salsesArea: res[0].salses_area,                                                 //売り場
        openCase: res[0].open_case,                                                     //オープンケース
        nakajimaChilled: res[0].nakajima_chilled,                                       //中島チルド
        islandChilled: res[0].island_chilled,                                           //アイランドチルド
        gondolaUnits: res[0].gondola_units,                                             //ゴンドラ　尺数
        gondolaHeight: res[0].gondola_height,                                           //ゴンドラ　高さ（mm）
        iceCaseLarge: res[0].ice_case_large,                                            //アイスケース　大
        iceCaseMedium: res[0].ice_case_medium,                                          //アイスケース　中
        iceCaseSmall: res[0].ice_case_small,                                            //アイスケース　小
        compactStocker: res[0].compact_stocker,                                         //コンパクトストッカー
        lowTempReachIn: res[0].low_temp_reach_in,                                       //低温リーチイン
        walkIn: res[0].walk_in,                                                         //ウォークイン
        conterWidth: res[0].conter_width,                                               //カウンター幅
        existingStoreName: res[0].existing_store_name,                                  //既存店名​
        storeNo: res[0].store_no,                                                       //店NO
        averageNippan: res[0].average_nippan,                                           //平均日版​
        achievementAcquisitionStart: res[0].achievement_acquisition_start,              //実績取得期間​　前
        achievementAcquisitionEnd: res[0].achievement_acquisition_end,                  //実績取得期間​　後
        receptionNo: res[0].reception_no,                                               //受付NO
        sStoreOpenDate: dayjs(res[0].s_store_open_date).format('YYYY-MM-DD'),           //S店開店日
        sStoreCloseDueDate: dayjs(res[0].s_store_close_due_date).format('YYYY-MM-DD'),  //S店閉店予定日
        closingDays: res[0].closing_days,                                               //休業日数
        inSalesConstructionDays: res[0].in_sales_construction_days,                     //営業中工事日数
        carCommonRatio: res[0].car_common_ratio,                                        //車共通比
        personCommonRatio: res[0].person_common_ratio,                                  //人共通比
        sStoreLineDistance: res[0].s_store_line_distance,                               //S店との直線距離
        sStoreWayDistance: res[0].s_store_way_distance,                                 //S店との道なり距離
        sStoreCarCustomerRatio: res[0].s_store_car_customer_ratio,                      //S店車客構成比
        lastfixedMonth: res[0].last_fixed_month,                                        //直近確定月
        lastOneYearNippan: res[0].last_one_year_nippan,                                 //直近1年日販
        yearOnYear: res[0].year_on_year,                                                //前年比
        yearMonth: res[0].year_month,                                                   //年月
        changeType:res[0].change_type,                                                  //変化の種類
        content: res[0].content,                                                        //内容
        lastOneYearNippan0: res[0].last_one_year_nippan_0,                              //直近1年日販（直近月）
        lastOneYearNippan1: res[0].last_one_year_nippan_1,                              //直近1年日販（直近月-1）
        lastOneYearNippan2: res[0].last_one_year_nippan_2,                              //直近1年日販（直近月-2）
        lastOneYearNippan3: res[0].last_one_year_nippan_3,                              //直近1年日販（直近月-3）
        lastOneYearNippan4: res[0].last_one_year_nippan_4,                              //直近1年日販（直近月-4）
        lastOneYearNippan5: res[0].last_one_year_nippan_5,                              //直近1年日販（直近月-5）
        lastOneYearNippan6: res[0].last_one_year_nippan_6,                              //直近1年日販（直近月-6）
        lastOneYearNippan7: res[0].last_one_year_nippan_7,                              //直近1年日販（直近月-7）
        lastOneYearNippan8: res[0].last_one_year_nippan_8,                              //直近1年日販（直近月-8）
        lastOneYearNippan9: res[0].last_one_year_nippan_9,                              //直近1年日販（直近月-9）
        lastOneYearNippan10: res[0].last_one_year_nippan_10,                            //直近1年日販（直近月-10）
        lastOneYearNippan11: res[0].last_one_year_nippan_11,                            //直近1年日販（直近月-11）
        yearOnYear0: res[0].year_on_year_0,                                             //前年比（直近月）
        yearOnYear1: res[0].year_on_year_1,                                             //前年比（直近月-1）
        yearOnYear2: res[0].year_on_year_2,                                             //前年比（直近月-2）
        yearOnYear3: res[0].year_on_year_3,                                             //前年比（直近月-3）
        yearOnYear4: res[0].year_on_year_4,                                             //前年比（直近月-4）
        yearOnYear5: res[0].year_on_year_5,                                             //前年比（直近月-5）
        yearOnYear6: res[0].year_on_year_6,                                             //前年比（直近月-6）
        yearOnYear7: res[0].year_on_year_7,                                             //前年比（直近月-7）
        yearOnYear8: res[0].year_on_year_8,                                             //前年比（直近月-8）
        yearOnYear9: res[0].year_on_year_9,                                             //前年比（直近月-9）
        yearOnYear10: res[0].year_on_year_10,                                           //前年比（直近月-10）
        yearOnYear11: res[0].year_on_year_11,                                           //前年比（直近月-11）
        storeMoveType: res[0].store_move_type,                                          //既存店敷地移転区分
        storeMoveDistance: res[0].store_move_distance,                                  //既存店移転時距離
        storeTypeMultipleStore: res[0].store_type_multiple_store                        //複合店舗
    };

    return DataObj;
};


//出店条件ページAPI登録
export const upsertStoreOpeningConditionsParam = (objStoreOpeningConditions:any, plan_area_id:any, branch:any) => {
    const params = {
        mode: "regist",
        endPoint: "/location/StoreOpeningConditions/v1/regist",
        query :{
            plan_area_id: plan_area_id,
            branch: branch,
            setting_reason: objStoreOpeningConditions.settingReason,                                            //ゾーン設定の理由
            candidate_location_ruins: objStoreOpeningConditions.candidateLocationRuins,                         //候補地跡地
            candidate_location_ruins_long_text: objStoreOpeningConditions.candidateLocationRuinsLongText,       //候補地跡地(ロングテキスト)
            is_store_name_select_reason: objStoreOpeningConditions.isStoreNameSelectReason,                     //所在地表示以外にて選定（選定根拠を下記に記載） チェックボックス
            removal_Reason: objStoreOpeningConditions.removalReason,                                            //理由
            open24_hours: objStoreOpeningConditions.open24Hours,                                                //24時間営業 有
            not_open24_hours: String(12 - Number(objStoreOpeningConditions.open24Hours)),                       //24時間営業 無
            alcohol_license: objStoreOpeningConditions.alcoholLicense,                                          //酒免許 有
            no_alcohol_license: String(12 - Number(objStoreOpeningConditions.alcoholLicense)),                  //酒免許 無
            tobacco_licence: objStoreOpeningConditions.tobaccoLicence,                                          //煙草免許 有
            no_tobacco_licence: String(12 - Number(objStoreOpeningConditions.tobaccoLicence)),                  //煙草免許 無
            area_type: objStoreOpeningConditions.areaType,                                                      //地域区分
            nearest_already_store_distance: objStoreOpeningConditions.nearestAlreadystoreDistance,              //最寄り既設店までの距離
            environment_type: objStoreOpeningConditions.environmentType,                                        //環境区分
            acquisition_requirement: objStoreOpeningConditions.acquisitionRequirement,                          //取得要件
            tobacco_distance_standard: objStoreOpeningConditions.tobaccoDistanceStandard,                       //煙草距離基準
            distance_standard_alreadystore_count: objStoreOpeningConditions.distanceStandardAlreadystoreCount,  //距離基準内の既設店舗数
            previous_job: objStoreOpeningConditions.previousJob,                                                //前職
            conflict_cvs: objStoreOpeningConditions.conflictCVS,                                                //元競合CVS
            cvs_last_nippan: objStoreOpeningConditions.cvsLastNippan,                                           //CVS直近日販
            relocation_distance: objStoreOpeningConditions.relocationDistance,                                  //移転
            ownership_type: objStoreOpeningConditions.ownershipType,                                            //所有形態​
            store_type: objStoreOpeningConditions.storeType,                                                    //店舗形態
            store_frontage: objStoreOpeningConditions.storefrontage,                                            //店舗間口
            store_area: objStoreOpeningConditions.storeArea,                                                    //店舗面積
            salses_area: objStoreOpeningConditions.salsesArea,                                                  //売り場
            open_case: objStoreOpeningConditions.openCase,                                                      //オープンケース
            nakajima_chilled: objStoreOpeningConditions.nakajimaChilled,                                        //中島チルド
            island_chilled: objStoreOpeningConditions.islandChilled,                                            //アイランドチルド
            gondola_units: objStoreOpeningConditions.gondolaUnits,                                              //ゴンドラ　尺数
            gondola_height: objStoreOpeningConditions.gondolaHeight,                                            //ゴンドラ　高さ（mm）
            ice_case_large: objStoreOpeningConditions.iceCaseLarge,                                             //アイスケース　大
            ice_case_medium: objStoreOpeningConditions.iceCaseMedium,                                           //アイスケース　中
            ice_case_small: objStoreOpeningConditions.iceCaseSmall,                                             //アイスケース　小
            compact_stocker: objStoreOpeningConditions.compactStocker,                                          //コンパクトストッカー
            low_temp_reach_in: objStoreOpeningConditions.lowTempReachIn,                                        //低温リーチイン
            walk_in: objStoreOpeningConditions.walkIn,                                                          //ウォークイン
            conter_width: objStoreOpeningConditions.conterWidth,                                                //カウンター幅
            existing_store_name: objStoreOpeningConditions.existingStoreName,                                   //既存店名​
            store_no: objStoreOpeningConditions.storeNo,                                                        //店NO
            average_nippan: objStoreOpeningConditions.averageNippan,                                            //平均日版​       
            achievement_acquisition_start: objStoreOpeningConditions.achievementAcquisitionStart,               //実績取得期間​　前
            achievement_acquisition_end: objStoreOpeningConditions.achievementAcquisitionEnd,                   //実績取得期間​　後
            reception_no: objStoreOpeningConditions.receptionNo,                                                //受付NO
            s_store_open_date: objStoreOpeningConditions.sStoreOpenDate,                                        //S店開店日                
            s_store_close_due_date: objStoreOpeningConditions.sStoreCloseDueDate,                               //S店閉店予定日
            closing_days: objStoreOpeningConditions.closingDays,                                                //休業日数
            in_sales_construction_days: objStoreOpeningConditions.inSalesConstructionDays,                      //営業中工事日数
            car_common_ratio: objStoreOpeningConditions.carCommonRatio,                                         //車共通比
            person_common_ratio: objStoreOpeningConditions.personCommonRatio,                                   //人共通比
            s_store_line_distance: objStoreOpeningConditions.sStoreLineDistance,                                //S店との直線距離
            s_store_way_distance: objStoreOpeningConditions.sStoreWayDistance,                                  //S店との道なり距離
            s_store_car_customer_ratio: objStoreOpeningConditions.sStoreCarCustomerRatio,                       //S店車客構成比
            last_fixed_month: objStoreOpeningConditions.lastfixedMonth,                                         //直近確定月
            last_one_year_nippan: objStoreOpeningConditions.lastOneYearNippan,                                  //直近1年日販
            year_on_year: objStoreOpeningConditions.yearOnYear,                                                 //前年比
            year_month: objStoreOpeningConditions.yearMonth,                                                    //年月
            change_type: objStoreOpeningConditions.changeType,                                                  //変化の種類
            content: objStoreOpeningConditions.content,                                                         //内容
            last_one_year_nippan_0: objStoreOpeningConditions.lastOneYearNippan0,                               //直近1年日販（直近月）
            last_one_year_nippan_1: objStoreOpeningConditions.lastOneYearNippan1,                               //直近1年日販（直近月-1）
            last_one_year_nippan_2: objStoreOpeningConditions.lastOneYearNippan2,                               //直近1年日販（直近月-2）
            last_one_year_nippan_3: objStoreOpeningConditions.lastOneYearNippan3,                               //直近1年日販（直近月-3）
            last_one_year_nippan_4: objStoreOpeningConditions.lastOneYearNippan4,                               //直近1年日販（直近月-4）
            last_one_year_nippan_5: objStoreOpeningConditions.lastOneYearNippan5,                               //直近1年日販（直近月-5）
            last_one_year_nippan_6: objStoreOpeningConditions.lastOneYearNippan6,                               //直近1年日販（直近月-6）
            last_one_year_nippan_7: objStoreOpeningConditions.lastOneYearNippan7,                               //直近1年日販（直近月-7）
            last_one_year_nippan_8: objStoreOpeningConditions.lastOneYearNippan8,                               //直近1年日販（直近月-8）
            last_one_year_nippan_9: objStoreOpeningConditions.lastOneYearNippan9,                               //直近1年日販（直近月-9）
            last_one_year_nippan_10: objStoreOpeningConditions.lastOneYearNippan10,                             //直近1年日販（直近月-10）
            last_one_year_nippan_11: objStoreOpeningConditions.lastOneYearNippan11,                             //直近1年日販（直近月-11）
            year_on_year_0: objStoreOpeningConditions.yearOnYear0,                                              //前年比（直近月）
            year_on_year_1: objStoreOpeningConditions.yearOnYear1,                                              //前年比（直近月-1）
            year_on_year_2: objStoreOpeningConditions.yearOnYear2,                                              //前年比（直近月-2）
            year_on_year_3: objStoreOpeningConditions.yearOnYear3,                                              //前年比（直近月-3）
            year_on_year_4: objStoreOpeningConditions.yearOnYear4,                                              //前年比（直近月-4）
            year_on_year_5: objStoreOpeningConditions.yearOnYear5,                                              //前年比（直近月-5）
            year_on_year_6: objStoreOpeningConditions.yearOnYear6,                                              //前年比（直近月-6）
            year_on_year_7: objStoreOpeningConditions.yearOnYear7,                                              //前年比（直近月-7）
            year_on_year_8: objStoreOpeningConditions.yearOnYear8,                                              //前年比（直近月-8）
            year_on_year_9: objStoreOpeningConditions.yearOnYear9,                                              //前年比（直近月-9）
            year_on_year_10: objStoreOpeningConditions.yearOnYear10,                                            //前年比（直近月-10）
            year_on_year_11: objStoreOpeningConditions.yearOnYear11,                                            //前年比（直近月-11）
            store_move_type: objStoreOpeningConditions.storeMoveType,                                           //既存店敷地移転区分
            store_move_distance: objStoreOpeningConditions.storeMoveDistance,                                   //既存店移転時距離
            store_type_multiple_store: objStoreOpeningConditions.storeTypeMultipleStore                         //複合店舗
        }
    };
    return params;
};

//2024年5月の文字列を年と月に分ける関数
export const parseDateString = (dateString:string) => {
    const year = parseInt(dateString.substring(0,4),10);
    const month = parseInt(dateString.substring(5, dateString.length-1),10);
    return {year, month};
};

//2024と5の数字から12か月分を取得する関数
export const getTwelveMonths = (year:number, month:number) => {
    const twelveMonths:{colName:string,label:string}[] = [];
    for (let j=0; j<12; j++) {
        const monthObj = 
            {
                colName:'lastMonthMinus'+j,
                label: dayjs(new Date(year, month-1)).subtract(j, 'month').format('M月')
            }
        twelveMonths.push(monthObj);
    }
    return twelveMonths.reverse();
}

//休業日数の計算
export const closingDaysFunc = (openDueDate:any, sStoreCloseDueDate:any) => {
    //表紙の開店予定日 - 出店条件のS店閉店予定日 = 休業日数
    const parseOpenDueDate = dayjs(openDueDate, 'YYYY-MM-DD', true);
    const parseCloseDueDate = dayjs(sStoreCloseDueDate, 'YYYY-MM-DD', true);
    //日付チェック
    const isCheckOpenDate = parseOpenDueDate.isValid();
    const isCheckCloseDate = parseCloseDueDate.isValid();
    //日付の引き算で日数を計算
    return isCheckOpenDate && isCheckCloseDate ? parseOpenDueDate.diff(parseCloseDueDate, 'day') : '';
};

//avgCulcNippan
//各月の直近1年日販/前年比*100)の全月平均 値が入っていない月は平均対象外
export const avgCulcNippanFunc = (nippan:any[], onYear:any[]) => {
    let totalNippan = 0;
    let countNippan = 0;
    for (let i = 0; i < nippan.length; i++) {
        if (nippan[i] !== '' && onYear[i] !== '' && parseFloat(onYear[i]) !== 0) {
            totalNippan += parseInt(nippan[i])/parseFloat(onYear[i])*100
            countNippan++;
        }
    }
    return countNippan !== 0 ? totalNippan/countNippan : '';
};

//avgCulcPrevRatio
export const avgCulcPrevRatioFunc = (monthHeader:any[], onYear:any[], yearMonth:any) => {
    //年月が入ってない場合は、avgCulcPrevRatioの値なし
    if (yearMonth === '') {
        return '';
    }
    const monthHeaderList:any[] = []; 
    //月の配列
    monthHeader.map((data:any) => {
        monthHeaderList.push(data.label);
    })
    //該当月のインデックスを設定
    const startIndex = monthHeaderList.indexOf(yearMonth);
    let totalPrevRatio = 0;
    let countPrevRatio = 0;
    
    //該当月が見つかった場合、そのインデックス以降の前年比の平均を返す
    //該当月の値がない場合は0を設定し平均対象とする
    if (startIndex !== -1) {
        for (let i = startIndex; i < onYear.length; i++) {
            totalPrevRatio += onYear[i] !== '' ? parseFloat(onYear[i]) : 0;
            countPrevRatio++;
        }
        return countPrevRatio !== 0 ? totalPrevRatio/countPrevRatio : '';
    }
    
    return '';
};