import * as React from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setConflict } from '../../features/conflictSlice';
import {
    InputLabel,
    Box,
    Button,
} from '@mui/material';
import { LOCAL_CONSTANT } from "../Const";

import { useUtilityContext } from '../../utility-provider';
import { useUserContext } from '../../user-provider';
import {
    getLocationConflict,
} from "../../data/DataManage";
import {
    conflictResearchList,
} from "../subPage/Interface";
import ConflictList from '../subPage/Conflict/ConflictList';
import AddConflict from '../subPage/Conflict/AddConflict';
import ActualCommercialArea from '../subPage/Conflict/ActualCommercialArea';
import {
    tradeAreaDensityFunc,
  } from'../subPage/TradeArea/TradeAreaFunction';
import { setPermissionFunc } from '../components/PermissionFunc';


const CENTER = {
    lat: 35.57921063,
    lng: 140.1751991,
}
export const CVS_MASTER_DATA = 'cvs-master-data';
export const SM_DRUG_MASTER_DATA = 'sm-drug-master-data';
const DISTANCE = 1
export interface resultDialogProps {
    props:{
    }
}

const Conflict:React.FC<resultDialogProps> = ({props}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [reload, setReload] = React.useState<boolean>(false)
    const utilityCtx = useUtilityContext();
    const [locationConflict, setLocationConflict] = React.useState<conflictResearchList[]>([]);
    const [locationSMDS, setLocationSMDS] = React.useState<conflictResearchList[]>([]);
    const [ownStore, setOwnStore] = React.useState()
    const [type, setType] = React.useState<string>("")
    const [targetRadius, setTargetRadius] = React.useState<string>("");

    const callApi = async (target: string) => {
        if (target === 'locationConflict') {
          return await getLocationConflict({
            mode: "getLocationConflict",
            plan_area_id: objLongPageParam.planAreaId,
            branch: objLongPageParam.branch,
          })
        }
    }
    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    const objConflict = useAppSelector((state) => state.conflict.conflictObj);
    //④商圏データRedux
    const listMpData = useAppSelector((state) => state.tradeArea.mpDataList);
    //タスクIDを取得するため
    const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
    const [userType, setUserType] = React.useState('');
    const user = useUserContext();
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        const tmp: any = []
        const tmpSMDS: any = []
        if (objConflict.length > 0) {
            const copyData = JSON.parse(JSON.stringify(objConflict))
            copyData.map((row: any) => {
                // 99（自店）
                if (row.conflict_sequence !== '99') {
                    if (['0', '1'].includes(row.conflict_type)) {
                        tmp.push(row)
                    } else if (['2', '3'].includes(row.conflict_type)) {
                        tmpSMDS.push(row)
                    }
                } else {
                    setOwnStore(row)
                }
            })
        }
        const result: string|undefined = tradeAreaDensityFunc('afterModified', listMpData)
        if (result) {
            if (Number(result) >= 4) {
                setTargetRadius('1')
            } else if (Number(result) === 3) {
                setTargetRadius('2')
            } else {
                setTargetRadius('3')
            }
        } else {
            setTargetRadius("")
        }
        setLocationConflict(tmp)
        setLocationSMDS(tmpSMDS)
    }, [objConflict])

    React.useEffect(() => {
        if (reload) {
            if (utilityCtx.showSpinner) {
                utilityCtx.showSpinner();
            }
            try {
                // 競合調査 呼び出し
                callApi('locationConflict').then((res: any) => {
                    dispatch(setConflict(res));
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                    setReload(false)
                }).catch((e) => {
                    console.log(e)
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                    setReload(false)
                })
            } catch (e) {
                console.log(e)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                setReload(false)
            }
        }
    }, [reload])

    //ユーザー取得タイミングによる画面制御
    React.useEffect(() => {
        if (JSON.parse(user.userData || 'null') !== null) {
            setUserType(JSON.parse(user.userData || 'null').user_type);
        }
    }, [user]);

    const clickHundle = (target: string) => {
        switch(target) {
            case 'CVS_MASTER_DATA':
                setType(CVS_MASTER_DATA)
                setOpen(true)
                break;
            case 'SM_DRUG_MASTER_DATA':
                setType(SM_DRUG_MASTER_DATA)
                setOpen(true)
                break;
        }
    }

    return (
        <>
            <Box sx={{ width: "96%", margin: "30px" }}>
                <Box>
                    <InputLabel>{LOCAL_CONSTANT.LABEL.CONFLICT_RESEARCH}</InputLabel>
                    <Button
                        sx={{margin: '20px 0'}}
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={() => {clickHundle('CVS_MASTER_DATA')}}
                        disabled={setPermissionFunc(userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.CONFLICT,'CVS_MASTER_DATA')}
                    >{LOCAL_CONSTANT.LABEL.CONFLICT_SETTING}</Button>
                </Box>
                <Box sx={{width: '100%'}}>
                    <ConflictList props={{
                        ownStore: ownStore,
                        locationConflict: locationConflict,
                        setReload: setReload,
                        type: CVS_MASTER_DATA,
                        targetRadius: targetRadius,
                        userType:userType,
                    }} />
                </Box>
            </Box>
            <Box sx={{ width: "96%", margin: "30px" }}>
                <Box>
                    <Button
                        sx={{margin: '20px 0'}}
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={() => {clickHundle('SM_DRUG_MASTER_DATA')}}
                        disabled={setPermissionFunc(userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.CONFLICT,'CONFLICT_SM_SETTING')}
                    >{LOCAL_CONSTANT.LABEL.CONFLICT_SM_SETTING}</Button>
                </Box>
                <Box sx={{width: '100%'}}>
                    <ConflictList props={{
                        ownStore: ownStore,
                        locationConflict: locationSMDS,
                        setReload: setReload,
                        type: SM_DRUG_MASTER_DATA,
                        targetRadius: targetRadius,
                        userType:userType,
                    }} />
                </Box>
            </Box>
            <Box sx={{ width: "96%", margin: "30px" }}>
                <Box sx={{width: '100%'}}>
                    <ActualCommercialArea props={{
                        clickHundle: clickHundle,
                        locationConflict: locationConflict,
                        locationSMDS: locationSMDS,
                    }} />
                </Box>
            </Box>
            <AddConflict props={{
                open:open,
                handleModal:setOpen,
                center: CENTER,
                distance: DISTANCE,
                locationConflict: type === CVS_MASTER_DATA 
                ? locationConflict
                : type === SM_DRUG_MASTER_DATA
                ? locationSMDS
                : [],
                setReload: setReload,
                type: type,
            }}/>
        </>
    );
        
}


export default Conflict;
