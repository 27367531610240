import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';
import * as React from 'react';
import { useRef } from 'react';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import EditIcon from '@mui/icons-material/Edit';
import {
  DonationBooleanTypeList,
  DonationStringTypeList,
  DonationTypeList,
  PictureBooleanTypeList,
  PictureStringTypeList,
  StoreOpeningConditionsBooleanTypeList,
  StoreOpeningConditionsStringTypeList,
  StoreOpeningConditionsTypeList,
  ConflictBooleanTypeList,
  ConflictStringTypeList,
} from '../../data/type';
import { setPermissionFunc } from '../components/PermissionFunc';
import { useAppDispatch, useAppSelector } from '../../app/hooks';


export interface InputProps {
  //全ページ対応できるように各ページ型を追加していく
  props: {
    userType: string,
    disabled: boolean,
    pageName: string,
    columnName: string,
    labelName: string,
    startAdornment: string,
    endAdornment: string,
    CommentObj: StoreOpeningConditionsStringTypeList
    | DonationStringTypeList
    | PictureStringTypeList
    | ConflictStringTypeList
    | any,
    InputValueObj: StoreOpeningConditionsTypeList
    | DonationTypeList
    | PictureBooleanTypeList
    | any,
    ReadOnlyObj: StoreOpeningConditionsBooleanTypeList
    | DonationBooleanTypeList
    | PictureBooleanTypeList
    | ConflictBooleanTypeList
    | any,
    setReadOnlyObj: React.Dispatch<React.SetStateAction<StoreOpeningConditionsBooleanTypeList>>
    | React.Dispatch<React.SetStateAction<DonationBooleanTypeList>>
    | React.Dispatch<React.SetStateAction<PictureBooleanTypeList>>
    | React.Dispatch<React.SetStateAction<ConflictBooleanTypeList>>,
    ShowEditIconObj: StoreOpeningConditionsBooleanTypeList
    | DonationBooleanTypeList
    | PictureBooleanTypeList
    | ConflictBooleanTypeList,
    ShowIconsObj: StoreOpeningConditionsBooleanTypeList
    | DonationBooleanTypeList
    | PictureBooleanTypeList
    | ConflictBooleanTypeList
    | any,
    handleChatBubbleClick: (columnName: string, labelName: string) => void,
    handleInputBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>, labelName: string) => void,
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => void,
    handleInputClick: (labelName: string) => void,
  }
};

// 吹き出しスタイル調整
export const chatBubbleCircleSx = {
  backgroundColor: '#1565c0',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '35px',
  height: '35px',
};

// 吹き出しアイコンのスタイル調整
export const ChatBubbleIconSx = {
  color: 'white'
};

//鉛筆スタイル調整
export const editIconCircleSx = {
  backgroundColor: '#e0e0e0',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '35px',
  height: '35px',
};

//アイコンホバー時のスタイル調整
export const iconHover = {
  padding: '8px 0px 8px 4px',//アイコンのpaddingを調整
  '&:hover': {
    backgroundColor: 'transparent',
  }
};

export const InputWithIcons: React.FC<InputProps> = ({ props }) => {

  const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
  // 鉛筆をクリックしたときのフォーカス制御
  const inputRef = useRef<HTMLInputElement | null>(null);

  // 鉛筆をクリックしたとき、Input編集可
  const handleEditIconClick = (labelName: string) => {
    if (setPermissionFunc(props.userType, objLocationBase.task_id, props.pageName, labelName) === false) {
      //falseにすると、Input編集可
      props.setReadOnlyObj({ ...props.ReadOnlyObj, [labelName]: false });
    }
    //インプットにフォーカスする
    if (inputRef.current) {
      inputRef.current.focus();
    };
  };

  //ユーザー種別（'z002'、'z004'、'z005'）が選択されている場合のTextFieldのスタイル調整
  const TextFieldSx = {
    '& .MuiFilledInput-input': {
      color: 'rgba(0, 0, 0, 0.38)',
      textAlign: 'center', //入力した値を中央寄せ
      paddingTop: '17px',
      paddingBottom: '17px'
    },
    '& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-filled.MuiInputAdornment-sizeMedium': {
      marginTop: '0px'
    },
    //disabledがtrueのとき背景がグレー
    '& .Mui-disabled.MuiInputBase-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.06)',
      '&: hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.06)'
      },
    },
    '& .MuiInputBase-root.MuiFilledInput-root.Mui-disabled:before': {
      borderBottomStyle: 'solid'
    }
  };

  //ユーザー種別（'z003'）が選択されている場合のTextFieldのスタイル調整 
  const SelectZ003TextFieldSx = {
    '& .MuiFilledInput-input': {
      color: props.disabled ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0)',
      textAlign: 'center', //入力した値を中央寄せ
      paddingTop: '17px',
      paddingBottom: '17px'
    },
    '& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-filled.MuiInputAdornment-sizeMedium': {
      marginTop: '0px'
    },
    cursor: props.ReadOnlyObj[props.labelName] ? 'not-allowed' : 'text', //鉛筆アイコンをクリック時にTextFieldにカーソルを表示
    '& .MuiInputBase-root.MuiFilledInput-root': {
      backgroundColor: props.disabled ? 'rgba(0, 0, 0, 0.06)' : '#FFFCD6', //trueのとき背景がグレー系、色を変える
    },
    '& .MuiInputBase-root.MuiFilledInput-root.Mui-disabled:before': {
      borderBottomStyle: 'solid'
    }
  };

  //ユーザー種別（'z001'）が選択されている場合のTextFieldのスタイル調整 
  const SelectZ001TextFieldSx = {
    '& .MuiFilledInput-input': {
      color: props.ReadOnlyObj[props.labelName] ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0)',
      textAlign: 'center', //入力した値を中央寄せ
      paddingTop: '17px',
      paddingBottom: '17px'
    },
    '& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-filled.MuiInputAdornment-sizeMedium': {
      marginTop: '0px'
    },
    cursor: props.ReadOnlyObj[props.labelName] ? 'not-allowed' : 'text', //鉛筆アイコンをクリック時にTextFieldにカーソルを表示
    '& .MuiInputBase-root.MuiFilledInput-root': {
      backgroundColor: props.ReadOnlyObj[props.labelName] ? 'rgba(0, 0, 0, 0.06)' : '#FFFCD6', //trueのとき背景がグレー系、色を変える
    },
    '& .MuiInputBase-root.MuiFilledInput-root.Mui-disabled:before': {
      borderBottomStyle: 'solid'
    }
  };

  return (
    <Box>
      {/* ユーザー種別がRFCとリサーチ以外の場合*/}
      {(props.userType !== 'z003' && props.userType !== 'z001') && (
        <TextField
          fullWidth
          disabled={true}
          value={props.InputValueObj[props.labelName]}
          sx={TextFieldSx}
          InputProps={{
            startAdornment: <InputAdornment position="start">{props.startAdornment}</InputAdornment>,
            endAdornment: <InputAdornment position="end">{props.endAdornment}</InputAdornment>,
          }}
          variant="filled"
        />
      )}
      {/* ユーザー種別がRFCの場合 userTypeがz003*/}
      {props.userType === 'z003' && (
        <TextField
          fullWidth
          value={props.InputValueObj[props.labelName]}
          onChange={(e) => props.handleInputChange(e, props.labelName)}
          onBlur={(e) => props.handleInputBlur(e, props.labelName)}
          sx={SelectZ003TextFieldSx}
          InputProps={{
            readOnly: props.disabled,
            startAdornment: <InputAdornment position="start">{props.startAdornment}</InputAdornment>,
            endAdornment: <InputAdornment position="end">{props.endAdornment}</InputAdornment>,
          }}
          variant="filled"
        />
      )}
      {/* ユーザー種別がリサーチの場合 userTypeがz001 */}
      {props.userType === 'z001' && (
        <Box display="flex" alignItems="center">
          <TextField
            fullWidth
            value={props.InputValueObj[props.labelName]}
            onClick={() => props.handleInputClick(props.labelName)}
            onChange={(e) => props.handleInputChange(e, props.labelName)}
            onBlur={(e) => props.handleInputBlur(e, props.labelName)}
            inputRef={inputRef}
            sx={SelectZ001TextFieldSx}
            InputProps={{
              readOnly: props.ReadOnlyObj[props.labelName],
              startAdornment: <InputAdornment position="start">{props.startAdornment}</InputAdornment>,
              endAdornment: <InputAdornment position="end">{props.endAdornment}</InputAdornment>,
            }}
            variant="filled"
          />
          {/* アイコン全体の表示非表示 */}
          {props.ShowIconsObj[props.labelName] && (
            <Box sx={{ minWidth: '85px' }}>
              {/* 吹き出しアイコン */}
              <Tooltip title={props.CommentObj[props.labelName]} arrow>
                <IconButton onClick={() => props.handleChatBubbleClick(props.columnName, props.labelName)} sx={iconHover}>
                  <Box sx={chatBubbleCircleSx}>
                    <ChatBubbleIcon sx={ChatBubbleIconSx} />
                  </Box>
                </IconButton>
              </Tooltip>
              {/* 鉛筆アイコンの表示非表示 */}
              {props.ShowEditIconObj[props.labelName] && (
                <IconButton onClick={() => handleEditIconClick(props.labelName)} sx={iconHover}>
                  <Box sx={editIconCircleSx}>
                    <EditIcon />
                  </Box>
                </IconButton>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>

  );
};

export default InputWithIcons;