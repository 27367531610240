import * as React from 'react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useUserContext } from '../../../user-provider';
import { useUtilityContext } from '../../../utility-provider';
import { apiRequest, postPython } from '../../../api/ApiCall';
import {
  getFileMetadata,
  uploadFile,
  deleteUploadFile,
  getFileData
} from '../../../utility/storage';
import PdfViewer from '../../../utility/PdfViewer';
import {
  setAddSurveyCoverExcel,
  setAddSurveyCoverPdf,
  setLocationBase,
  setSaveStatus
} from '../../../features/longPageSlice';
import {
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Typography,
  Box,
  Button,
  TableContainer,
  Grid,
} from '@mui/material';
import { LOCAL_CONSTANT } from "../../Const"
import MessageDialog from '../../components/MessageDialog';
import { DeleteConfirmationModal } from '../../ResearchReport/DeleteConfirmationModal';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import {
  upsertLocationBaseFileParam,
  upsertLocationBaseDeleteFileParam
} from '../../components/LocationBaseFunc';
import { setAdditionalResearchCoverPdf } from '../../../features/additionalResearchSlice';


const headerCell = {
  backgroundColor: '#D9D9D9',
  fontSize: '12px',
  color: 'black',
  borderBottom: '1px solid black',
  fontWeight: 'Bold'
};

//参照値算出結果のラベル
const labels = [
  { header: '初年度', Name: 'add_survey_reference_value_1'},
  { header: '2年度', Name: 'add_survey_reference_value_2'},
  { header: '3年度', Name: 'add_survey_reference_value_3'},
  { header: '4年度', Name: 'add_survey_reference_value_4'},
  { header: '5年度', Name: 'add_survey_reference_value_5'}
];

interface AddCoverProps {
  props: {
  }
}

const AddCover: React.FC<AddCoverProps> = ({ props }) => {

  //候補地NOと枝を取得
  const objLongPageParam = useAppSelector((state) => state.longPage.param);
  //調査書基本を取得
  const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
  //ロングページ保存用ステータス
  const objLongPageSaveStatus = useAppSelector((state) => state.longPage.saveStatus);

  const dispatch = useAppDispatch();

  //ユーザIDを取得
  const [userId, setUserId] = useState<string>("");
  //ユーザー種別を取得
  const [userType, setUserType] = useState<string>("");
  const user = useUserContext();

  const utilityCtx = useUtilityContext();

  //ファイルアップロード
  const [message, setMessage] = useState<string>("");
  const [messageDialogOpen, setMessageDialogOpen] = useState<boolean>(false);
  const [hasButton, setHasButton] = useState<boolean>(false);

  //Excelアップロード情報
  const [addSurveyCoverExcelPath, setAddSurveyCoverExcelPath] = useState<any>({});
  const [addSurveyCoverPdfPath, setAddSurveyCoverPdfPath] = useState<any>({});

  //モーダル開閉フラグ(削除確認)
  const [deleteConfModalOpen, setDeleteConfModalOpen] = useState(false);
  const [path, setPath] = useState<string>("");
  const [pdfPath, setPdfPath] = useState<string>("");

  //参照値算出結果のテキストフィールド
  const [addSurveyReferenceValue, setAddSurveyReferenceValue] = useState<any>({
    add_survey_reference_value_1: '',
    add_survey_reference_value_2: '',
    add_survey_reference_value_3: '',
    add_survey_reference_value_4: '',
    add_survey_reference_value_5: '',
  });

  //プレビュー
  const previewPdfPath = useAppSelector((state) => state.additionalResearch.coverPdfPath);

  const handleValueChange = (path: any, labelName: string) => {
    if(previewPdfPath === path?.fullPath){
      dispatch(setAdditionalResearchCoverPdf(""));
    } else {
      dispatch(setAdditionalResearchCoverPdf(path.fullPath));
    }
  }

  //インプット 参照値算出結果
  const handleIntInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
    const targetValue = e.target.value;
    if (/^$|^0$|^[1-9]\d*$/.test(targetValue)) { //空文字列、0、または正の整数のみ可能
      setAddSurveyReferenceValue({ ...addSurveyReferenceValue, [labelName]: e.target.value });
    }
    //更新フラグ
    if (!objLongPageSaveStatus.LongPage) dispatch(setSaveStatus({...objLongPageSaveStatus,['LongPage']: true}));
  };

  //参照値算出結果をReduxへ保存
  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>, labelName: string) => {
    dispatch(setLocationBase({ ...objLocationBase, [labelName]: e.target.value}));
  };

  const handleModalOpen = (pathParam:string, pdfPathParam: string) => {
    setDeleteConfModalOpen(true);
    setPath(pathParam);
    setPdfPath(pdfPathParam)
  }

  const closeMessageDialog = () => {
    setMessage("");
    setHasButton(false);
    setMessageDialogOpen(false);
  };

  const handleClickFile = () => {
    const fileElem = document.getElementById("addCoverFileElem");
    if (fileElem) {
      fileElem.click();
    }
  };

  const handleChangeFile = (newFile: any) => {
    fileUpload(newFile.target.files[0]);
    newFile.target.value = '';
  };

  //ファイル保存
  const fileDownload = (data: any) => {
    (async () => {
      try {
        const url = await getFileData('/' + data.fullPath);
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;
          const link = document.createElement("a");
          document.body.appendChild(link);
          link.download = data.name;
          link.href = window.URL.createObjectURL(blob);
          link.click();
          document.body.removeChild(link);
        };
        xhr.open('GET', url as string);
        xhr.send();
      }catch(e){
        console.log(e);
      }
    })();
  }

  //ファイルアップロード
  const fileUpload = (file: any) => {
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner()
    }
    (async () => {
      try {
        setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.UPLOAD_FILE);
        setMessageDialogOpen(true);
        const file_name = objLongPageParam.planAreaId + '_追加調査表紙.xlsx'
        const pdf_file_name = objLongPageParam.planAreaId + '_追加調査表紙.pdf'
        await uploadFile(file, objLongPageParam.planAreaId + '/' + objLongPageParam.branch + '/excel/' + file_name);
        const uploadResultFile = await getFileMetadata('/'+objLongPageParam.planAreaId + '/' + objLongPageParam.branch + '/excel/' + file_name);
        setAddSurveyCoverExcelPath(uploadResultFile);
        const uploadFilePath = '/excel/' + file_name;
        // python
        await postPython({
          mode: "regist",
          endPoint: "/excel-add-print-pdf",
          query: {
              plan_area_id: objLongPageParam.planAreaId,
              branch: objLongPageParam.branch,
              file_name: file_name,
          }
        })
        const uploadResultPdfFile = await getFileMetadata('/'+objLongPageParam.planAreaId + '/' + objLongPageParam.branch + '/pdf/' + pdf_file_name);
        setAddSurveyCoverPdfPath(uploadResultPdfFile);
        //DB保存
        const params = upsertLocationBaseFileParam(objLocationBase, objLongPageParam.planAreaId, objLongPageParam.branch, uploadFilePath, 'add_survey_cover_excel_path', userId, file_name)
        params.query.add_survey_cover_pdf_path = '/pdf/' + pdf_file_name
        params.query.add_survey_cover_pdf_user = userId
        updateRegist(params);
        //Redux保存
        dispatch(setAddSurveyCoverExcel({
          ...objLocationBase,
          add_survey_cover_excel_path: uploadFilePath,
          add_survey_cover_excel_user: userId
        }));
        dispatch(setAddSurveyCoverPdf({
          ...objLocationBase,
          add_survey_cover_pdf_path: '/pdf/' + pdf_file_name,
          add_survey_cover_pdf_user: userId
        }));
        setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_UPLOAD_FILE);
        setHasButton(true)
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      } catch (e) {
        console.log(e);
        setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_UPLOAD_FILE);
        setHasButton(true);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      }
    })();
  };

  //ファイル削除
  const handleExcelDeleteChange = () => {
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
      try {
        // プレビュー閉じる
        dispatch(setAdditionalResearchCoverPdf(""));
        setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.DELETE_FILE)
        setMessageDialogOpen(true)
        //CloudStorageから削除対象のExcelファイル情報を取得
        const deleteFile = await getFileMetadata(path);
        await deleteUploadFile(deleteFile, path);
        //CloudStorageから削除対象のPdfファイル情報を取得
        const deletePdfFile = await getFileMetadata(pdfPath);
        await deleteUploadFile(deletePdfFile, pdfPath);
        //DB保存
        updateRegist(upsertLocationBaseDeleteFileParam(objLocationBase, objLongPageParam.planAreaId, objLongPageParam.branch, deleteFile, 'add_survey_cover_excel_path', "表紙"));
        //Redux保存
        dispatch(setAddSurveyCoverExcel({
          ...objLocationBase,
          add_survey_cover_excel_path: '',
          add_survey_cover_excel_user: ''
        }));
        dispatch(setAddSurveyCoverPdf({
          ...objLocationBase,
          add_survey_cover_pdf_path: '',
          add_survey_cover_pdf_user: ''
        }));
        setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_DELETE_FILE);
        setHasButton(true);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      } catch (e) {
        console.log(e);
        setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_DELETE_FILE);
        setHasButton(true);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      }
    })();
  }

  const updateRegist = (params: any) => {
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
      try {
        await apiRequest(params);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      } catch (e) {
        console.log(e);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      }
    })();
  };

  useEffect(() => {
    //ReduxをuseStateに保存
    setAddSurveyReferenceValue({
      ...objLocationBase,
        add_survey_reference_value_1:objLocationBase.add_survey_reference_value_1,
        add_survey_reference_value_2:objLocationBase.add_survey_reference_value_2,
        add_survey_reference_value_3:objLocationBase.add_survey_reference_value_3,
        add_survey_reference_value_4:objLocationBase.add_survey_reference_value_4,
        add_survey_reference_value_5:objLocationBase.add_survey_reference_value_5
    })
  },[objLocationBase]);

  //表紙ボタンしたにあるExcel情報を取得
  useEffect(() => {
    (async () => {
      try {
        if (objLocationBase.add_survey_cover_excel_path) {
          const uploadResultFile = await getFileMetadata('/'+objLongPageParam.planAreaId + '/' + objLongPageParam.branch + '/excel/' + objLongPageParam.planAreaId + '_追加調査表紙.xlsx');
          setAddSurveyCoverExcelPath(uploadResultFile)  
        }
        if (objLocationBase.add_survey_cover_pdf_path) {
          const resultFile = await getFileMetadata('/'+objLongPageParam.planAreaId + '/' + objLongPageParam.branch + objLocationBase.add_survey_cover_pdf_path);
          setAddSurveyCoverPdfPath(resultFile)  
        }
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      } catch (e) {
        console.log(e);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      }
    })();
  }, [objLocationBase.add_survey_cover_excel_user]); //追加調査_表紙_excel_ユーザーIDで制御

  //ユーザーID取得
  useEffect(() => {
    if (JSON.parse(user.userData || 'null') !== null) {
      setUserId(JSON.parse(user.userData || 'null').staff_number);
      setUserType(JSON.parse(user.userData || 'null').user_type);
    }
  }, [user]);

  return (
    <Box>

      {/* ユーザがリサーチのとき、「参照値算出結果」を表示 */}
      {userType === 'z001' && 
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ marginRight: '20px', marginTop: '40px', alignSelf: 'flex-start', whiteSpace: 'nowrap', fontWeight: 'bold' }}>参考値算出結果</Typography>
          <Grid container alignItems="center" maxWidth="lg">
            {labels.map((label, index) => (
              <Grid item xs={2} key={index}>
                <Typography align="center" sx={{ fontWeight: 'bold' }}>{label.header}</Typography>
                <TextField
                  value={addSurveyReferenceValue[label.Name]}
                  onChange={(e) => handleIntInputChange(e, label.Name)}
                  onBlur={(e) => handleInputBlur(e, label.Name)}
                  inputProps={{
                    sx: {textAlign: 'center', backgroundColor: '#FFFCD6'}
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      }

      <Box>
        <MessageDialog props={{
          open: messageDialogOpen,
          handleModal: closeMessageDialog,
          body: message,
          hasButton: hasButton,
          buttonBody: LOCAL_CONSTANT.LABEL.CLOSE
        }}
        />
        <input
          id="addCoverFileElem"
          type="file"
          style={{ display: 'none' }}
          onChange={handleChangeFile}
          accept=".xlsx"
        />
        <Button
          sx={{ margin: '20px 0' }}
          variant="contained"
          color="success"
          size="small"
          onClick={handleClickFile}
        >表紙</Button>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ paddingRight: "40px" }}>
          <TableContainer sx={{ height: "132px", width: "100%", border: '1px solid black' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.FILE_NAME}</TableCell>
                  <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOAD_DATE}</TableCell>
                  <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOADER}</TableCell>
                  <TableCell sx={headerCell}>Excel</TableCell>
                  <TableCell sx={headerCell}>PDF</TableCell>
                  <TableCell sx={headerCell}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(objLocationBase.add_survey_cover_excel_path !== "" && addSurveyCoverExcelPath !== undefined) ? (
                  <TableRow>
                    <TableCell onClick={() => handleValueChange(addSurveyCoverPdfPath, "previewPdfPath")}>{addSurveyCoverPdfPath?.name}</TableCell>
                    <TableCell onClick={() => handleValueChange(addSurveyCoverPdfPath, "previewPdfPath")}>{dayjs(addSurveyCoverPdfPath?.timeCreated).format('YYYY/MM/DD HH:mm:ss')}</TableCell>
                    <TableCell onClick={() => handleValueChange(addSurveyCoverPdfPath, "previewPdfPath")}>{objLocationBase?.add_survey_cover_excel_user}</TableCell>
                    <TableCell>
                      <IconButton sx={{ color: 'black' }} onClick={() => fileDownload(addSurveyCoverExcelPath)}><DownloadIcon /></IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton sx={{ color: 'black' }} onClick={() => fileDownload(addSurveyCoverPdfPath)}><DownloadIcon /></IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton sx={{ color: 'black' }} onClick={() => handleModalOpen('/' + addSurveyCoverExcelPath.fullPath, '/' + addSurveyCoverPdfPath.fullPath)}><DeleteIcon /></IconButton>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow sx={{ height: "73px" }}>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* プレビュー */}
      {previewPdfPath !== "" && (
        <Box sx={{ paddingTop: "30px" }}>
          <PdfViewer props={{ pdfPath: previewPdfPath }} />
        </Box>
      )}

      {/* 削除確認モーダル */}
      <DeleteConfirmationModal modalOpen={deleteConfModalOpen} setModalOpen={setDeleteConfModalOpen} deleteMethod={handleExcelDeleteChange} />
      
    </Box>
  );
}

export default AddCover;