import { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { apiRequest } from "../api/ApiCall";
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { setLongPageParamObj } from '../features/longPageSlice';
import { setObjPortal } from '../features/portalSlice';
import { useUtilityContext } from '../utility-provider';
import { useUserContext } from '../user-provider';

import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarExport,
  GridRowParams
} from '@mui/x-data-grid';
import { getReceptionBook } from '../../src/data/DataManage';
import { getPlanAreaList } from '../data/DataManage';
import MailCreateModal from './components/MailCreateModal';
import ResearchBranchModal from './components/ResearchBranchModal';
import GisImportModal from './components/GisImportModal';

interface ListItemProps {
  props: {
    width: number,
    height: number
  }
}

interface dataTypeList {
  id: string,
  status_task_update_date: string,
  elapsedTime: string,
  elapsedFlag: string,
  type: string,
  department: string,
  district: string,
  rfc: string,
  plan_area_id: string,
  candidateSiteName: string,
  assembly: string,
  pattern: string,
  branch: string,
  status_id: string,
  pic_type: string,
  pic_name: string,
  task_id: string,
  task_name: string,
  reception_no: string,
  plan_area_name: string,
  plan_area_staff_number: string,
  plan_area_staff_name: string,
  plan_area_district_code: string,
  plan_area_district_name: string,
  plan_area_department_code: string,
  plan_area_department_name: string
}

//「ステータス」プルダウンリスト用オブジェクト
let statusList: any[] = [];
//「依頼担当」プルダウンリスト用オブジェクト
let picTypeList: any[] = [];
//「依頼タスク」プルダウンリスト用オブジェクト
let requestTaskList: any[] = [];
//「依頼担当者」プルダウンリスト用オブジェクト
let picNameList: any[] = [];
//「部」プルダウンリスト用オブジェクト
let departmentList: any[] = [];
//「地区」プルダウンリスト用オブジェクト
let districtList: any[] = [];
//「候補地No」プルダウンリスト用オブジェクト
let planAreaIdList: any[] = [];
// 「RFC」プルダウンリスト用オブジェクト
let planAreaRfcList: any[] = [];
//テーブル表示用オブジェクト
let listDataContent: GridRowsProp = [];

//テーブルカスタムツールバー
const costomToolBar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          delimiter: ',',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
};

//スタイル
const InputFormCell = styled(TableCell)({
  padding: "4px",
  fontSize: "12px"
});
const NormalButton = styled(Button)({ //基本ボタン
  backgroundColor: "#FFFFFF",
  color: "#0E6F55",
  border: "1px solid #00AC4C",
});
const ModalButton = styled(ListItemButton)({
  backgroundColor: "#FFFFFF",
  color: "#0E6F55",
  border: "1px solid #00AC4C",
});
const BgColorButton = styled(Button)({ //強調ボタン
  backgroundColor: "#00AC4C",
  color: "#FFFFFF",
  float: "right",
  '&: hover':{
    backgroundColor: '#47cd82'
  }
});
const CancelButton = styled(Button)({
  backgroundColor: "#ffffff00",
  color: "#f00",
  border: "1px solid #f00",
  float: "left",
});
const styles = {
  dataGrid: {
    ".MuiDataGrid-toolbarContainer": {
      borderBottom: "solid 1px rgba(224, 224, 224, 1)",
      display: "block"
    },
    ".MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)": {
      borderRight: "solid 1px rgba(224, 224, 224, 1) !important"
    },
    ".MuiDataGrid-columnHeaders": {
      backgroundColor: "#00CC5B",
      color: "#fff",
    },
    ".MuiDataGrid-columnHeader": {
      border: "solid 1px rgba(224, 224, 224, 1)"
    },
    ".MuiDataGrid-virtualScrollerContent": {
      backgroundColor: "white"
    },
    ".MuiButtonBase-root": {
      float: "right"
    },
    "& .active-elapsed-row": {
      backgroundColor: "#f9d2d878"
    }
  },
  modalStyle: {
    ".MuiPaper-root": {
      width: "100%"
    },
  },
  disabledTextField: {
    ".MuiFormControl-root": {
      width: "100%"
    },
    ".MuiInputBase-root": {
      backgroundColor: "#7b7b7b5c"
    }
  },
}

const SecondaryListItems: React.FC<ListItemProps> = ({ props }) => {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const utilityCtx = useUtilityContext();
  const user = useUserContext();
  //データグリッドローディングフラグ
  const [dataGridLoading, setDataGridLoading] = useState(false);
  //ポータルオブジェクト
  const objPortal = useAppSelector((state) => state.portal);
  //モーダル開閉フラグ(テーブルレコード)
  const [tableModalOpen, setTableModalOpen] = useState(false);
  //モーダル開閉フラグ(手動登録)
  const [registModalOpen, setRegistModalOpen] = useState(false);
  //モーダル開閉フラグ(調査書枝選択画面)
  const [researchBranchOpen, setResearchBranchOpen] = useState(false);
  //モーダル開閉フラグ(メール作成画面)
  const [mailModalOpen, setMailModalOpen] = useState(false);
  //モーダル開閉フラグ(調査書枝選択画面)
  const [gisImportModalOpen, setGisImportModalOpen] = useState(false);
  //検索パラメータ用(依頼受付日)
  const [statusTaskUpdateDate, setStatusTaskUpdateDate] = useState<string>("");
  //検索パラメータ用(候補地No)
  const [planAreaId, setPlanAreaId] = useState<string | null>("");
  //検索パラメータ用(候補地名)
  const [planAreaName, setPlanAreaName] = useState<string | null>("");
  //検索パラメータ用(ステータス)
  const [statusId, setStatusId] = useState<string[]>([]);
  //検索パラメータ用(部)
  const [departmentCode, setDepartmentCode] = useState<string[]>([]);
  //検索パラメータ用(担当依頼)
  const [picType, setPicType] = useState<string[]>([]);
  //検索パラメータ用(地区)
  const [districtCode, setDistrictCode] = useState<string[]>([]);
  //検索パラメータ用(依頼担当者)
  const [picName, setPicName] = useState<string[]>([]);
  //検索パラメータ用(依頼タスク)
  const [taskId, setTaskId] = useState<string[]>([]);
  //検索パラメータ用(RFC)
  const [planAreaRfc, setPlanAreaRfc] = useState<string[]>([]);
  //候補地No(手動登録)
  const [registPlanAreaId, setRegistPlanAreaId] = useState<string>();
  //候補地名(手動登録)
  const [registPlanAreaName, setRegistPlanAreaName] = useState<string>();
  //テーブル表示用オブジェクト
  const [rows, setRows] = useState(listDataContent);
  //調査書画面遷移時パラメータ(候補地No)
  const [selectPlanAreaId, setSelectPlanAreaId] = useState("");
  //調査書画面遷移時パラメータ(枝)
  const [selectBranch, setSelectBranch] = useState("");
  //全レコード保持用オブジェクト
  const [allDataObj, setAllDataObj] = useState<dataTypeList[]>();
  //調査書枝選択モーダル用オブジェクト
  const [selectDataObj, setSelectDataObj] = useState<dataTypeList[]>();
  //メール作成モーダル用オブジェクト
  const [selectMailObj, setSelectMailObj] = useState<dataTypeList>();
  //value格納用(ステータス)
  const [statusValueList, setStatusValueList] = useState<any[]>([]);
  //value格納用(依頼担当)
  const [picTypeValueList, setPicTypeValueList] = useState<any[]>([]);
  //value格納用(依頼タスク)
  const [taskValueList, setTaskValueList] = useState<any[]>([]);
  //value格納用(依頼担当者)
  const [picNameValueList, setPicNameValueList] = useState<any[]>([]);
  //value格納用(地区)
  const [districtValueList, setDistrictValueList] = useState<any[]>([]);
  //value格納用(部)
  const [departmentValueList, setDepartmentValueList] = useState<any[]>([]);
  //value格納用(RFC)
  const [rfcValueList, setRfcValueList] = useState<any[]>([]);
  //バッチローディングフラグ
  const [batchLoadingFlag, setBatchLoadingFlag] = useState(false);
  //ユーザータイプ
  const [userType, setUserType] = useState<string>("");
  //部署名(RFC、RM、GM)
  const [departmentName, setDepartmentName] = useState<string>("");
  //地区名(RFC、RM)
  const [districtName, setDistrictName] = useState<string>("");
  //検索非活性フラグ
  const [disabledSearch, setDisabledSearch] = useState(true);
  //テーブル項目
  const columns: GridColDef[] = [
    { field: 'status_task_update_date', headerName: '依頼受領日時', width: 160, headerAlign: 'center', align: 'center', disableColumnMenu: true, resizable: true },
    { field: 'elapsedTime', headerName: '経過時間', width: 140, headerAlign: 'center', disableColumnMenu: true, resizable: true },
    { field: 'type', headerName: '調査書種別', width: 110, headerAlign: 'center', disableColumnMenu: true, resizable: true },
    { field: 'plan_area_department_name', headerName: '部', width: 140, headerAlign: 'center', disableColumnMenu: true, resizable: true },
    { field: 'plan_area_district_name', headerName: '地区', width: 100, headerAlign: 'center', disableColumnMenu: true, resizable: true },
    { field: 'plan_area_staff_name', headerName: 'RFC', width: 120, headerAlign: 'center', disableColumnMenu: true, resizable: true },
    { field: 'plan_area_id', headerName: '候補地No', width: 100, headerAlign: 'center', disableColumnMenu: true, resizable: true },
    { field: 'plan_area_name', headerName: '候補地名', width: 220, headerAlign: 'center', disableColumnMenu: true, resizable: true },
    { field: 'assembly', headerName: '組立', width: 70, headerAlign: 'center', disableColumnMenu: true, resizable: true },
    { field: 'pattern', headerName: 'パターン', width: 80, headerAlign: 'center', disableColumnMenu: true, resizable: true },
    { field: 'branch', headerName: '枝', width: 60, headerAlign: 'center', disableColumnMenu: true, resizable: true },
    { field: 'status_id', headerName: 'ステータス', width: 140, headerAlign: 'center', disableColumnMenu: true, resizable: true },
    { field: 'pic_type', headerName: '担当', width: 90, headerAlign: 'center', disableColumnMenu: true, resizable: true },
    { field: 'pic_name', headerName: '担当者名', width: 120, headerAlign: 'center', disableColumnMenu: true, resizable: true },
    { field: 'task_name', headerName: 'タスク', width: 100, headerAlign: 'center', disableColumnMenu: true, resizable: true },
  ];
  //テーブル表示列管理用ステート
  const [visibleColumns, setVisibleColumns] = useState(columns.map((col)=>col.field));

  //モーダルオープン(テーブルレコード押下時)
  const handleTableModalOpen = (selectionModel: any) => {
    const resultObj: dataTypeList[] = [];
    let selectRowPlanAreaId: string = "";
    //押下したレコードの候補地No、枝、RFC、RMセット
    rows.map((elem) => {
      if (elem.id === String(selectionModel)) {
        selectRowPlanAreaId = elem.plan_area_id;
        setSelectMailObj(elem as dataTypeList);
        setSelectPlanAreaId(elem.plan_area_id);
        setSelectBranch(elem.branch);
      }
    });
    //全データから押下した候補地Noの全枝番を取得
    if (typeof allDataObj !== "undefined") {
      allDataObj.map((elem) => {
        if (elem.plan_area_id === selectRowPlanAreaId) {
          resultObj.push(elem);
        }
      })
    }
    console.log(resultObj)
    setSelectDataObj(resultObj);
    setTableModalOpen(true);
  };

  //モーダルクローズ(テーブルレコード)
  const handleTableModalClose = () => {
    setTableModalOpen(false);
  };

  //モーダルオープン(手動登録)
  const handleRgistModalOpen = () => {
    setRegistModalOpen(true);
  };

  //モーダルクローズ(手動登録)
  const handleRegistModalClose = () => {
    setRegistModalOpen(false);
  };

  //モーダルオープン(調査書枝選択画面)
  const handleResearchBranchOpen = () => {
    setResearchBranchOpen(true);
  };

  //モーダルオープン(メール作成画面)
  const handleMailModalOpen = () => {
    setMailModalOpen(true);
  };

  //モーダルオープン(GIS取込)
  const handleGisImportModalOpen = () => {
    setGisImportModalOpen(true);
  };

  const closeGisImportModal = () => {
    setGisImportModalOpen(false);
  };

  //プルダウンの検索値パラメータ設定
  const setSearchParams = (pullDownType: string) => {
    let statusIdValues: string[] = [];
    let picTypesValues: string[] = [];
    let taskIdValues: string[] = [];
    let picNameValues: string[] = [];
    let districtValues: string[] = [];
    let departmentValues: string[] = [];
    let planAreaRfcValues: string[] = [];
    if (pullDownType === "status") {
      statusValueList.map((e: any) => {
        statusIdValues.push(e.value);
      })
      setStatusId(statusIdValues);
    } else if (pullDownType === "picType") {
      picTypeValueList.map((e: any) => {
        picTypesValues.push(e.value);
      })
      setPicType(picTypesValues);
    } else if (pullDownType === "task") {
      taskValueList.map((e: any) => {
        taskIdValues.push(e.value);
      })
      setTaskId(taskIdValues);
    } else if (pullDownType === "picName") {
      picNameValueList.map((e: any) => {
        picNameValues.push(e.value);
      })
      setPicName(picNameValues);
    } else if (pullDownType === "district") {
      districtValueList.map((e: any) => {
        districtValues.push(e.value);
      })
      setDistrictCode(districtValues);
    } else if (pullDownType === "department") {
      departmentValueList.map((e: any) => {
        departmentValues.push(e.value);
      })
      setDepartmentCode(departmentValues);
    } else if (pullDownType === "planAreaRfc") {
      rfcValueList.map((e: any) => {
        planAreaRfcValues.push(e.value);
      })
      setPlanAreaRfc(planAreaRfcValues);
    }
  };

  //自動検索処理
  const batchSearch = () => {
    //自動検索フラグがfalseの場合は処理しない
    if(!batchLoadingFlag) { return; }
    //ポータル画面以外処理しない
    if (location.pathname !== "/") { 
      setBatchLoadingFlag(false); 
      return;
    }
    //検索API呼び出し
    try {
      callApi('batchGetReceptionBook').then((res: any) => {        
        //全データ登録
        setAllDataObj(res);
        //テーブル表示用オブジェクト登録
        const dataObj = findLatestBranch(res);
        setRows(dataObj);
        setDataGridLoading(false);
      }).catch((e) => {
        console.log(e);
      })
    } catch (e) {
      console.log(e);
    }
  }

  //手動検索処理
  const handleSerch = () => {
    setDataGridLoading(true); 
    //自動検索処理フラグ更新
    setBatchLoadingFlag(true);
    // インプットデータをReduxへ格納
    dispatch(
      setObjPortal({
        ...objPortal,
        ["statusTaskUpdateDate"]: statusTaskUpdateDate,
        ["statusId"]: statusId,
        ["picType"]: picType,
        ["picName"]: picName,
        ["taskId"]: taskId,
        ["planAreaId"]: planAreaId,
        ["departmentCode"]: departmentCode,
        ["districtCode"]: districtCode,
        ["planAreaName"]: planAreaName,
        ["planAreaRfc"]: planAreaRfc,
        ["statusValues"]: statusValueList,
        ["picTypeValues"]: picTypeValueList,
        ["taskValues"]: taskValueList,
        ["picNameValues"]: picNameValueList,
        ["districtValues"]: districtValueList,
        ["departmentValues"]: departmentValueList
      })
    );
    //検索API呼び出し
    try {
      callApi('searchGetReceptionBook').then((res: any) => {        
        //全データ登録
        setAllDataObj(res);
        //テーブル表示用オブジェクト登録
        const dataObj = findLatestBranch(res);
        setRows(dataObj);
        setDataGridLoading(false);
      }).catch((e) => {
        console.log(e);
      })
    } catch (e) {
      console.log(e);
    }
  };

  //テーブル表示用オブジェクト生成
  const findLatestBranch = (dataObj: dataTypeList[]) => {
    //グループ化
    const groupeByKeys: Record<string, dataTypeList[]> = {};

    //候補地No毎に切り分け
    dataObj.forEach(obj => {
      const key = obj.plan_area_id;
      //候補地NoがXまたはYから始まる候補地を除外
      if(!key.startsWith('X') && !key.startsWith('Y') ){
        if (!groupeByKeys[key]) {
          groupeByKeys[key] = [];
        }
        groupeByKeys[key].push(obj);
      }
    })

    const resultObj: dataTypeList[] = [];
    //一番新しい枝のレコードのみ特定して返却
    for (const key in groupeByKeys) {
      if (groupeByKeys.hasOwnProperty(key)) {
        const values = groupeByKeys[key];
        const latestBranch = values.sort((a, b) => Number(b.branch.charCodeAt(0)) - Number(a.branch.charCodeAt(0)))[0];
        resultObj.push(latestBranch);
      }
    }
    return resultObj;
  };

  //API呼び出し
  const callApi = async (target: string) => {
    //テーブル表示用データ取得(自動検索)
    if (target === 'batchGetReceptionBook') {
      return await getReceptionBook({
        mode: "getReceptionBook",
        status_task_update_date: objPortal["statusTaskUpdateDate"],
        status_id: objPortal["statusId"],
        pic_type: objPortal["picType"],
        pic_name: objPortal["picName"],
        task_id: objPortal["taskId"],
        plan_area_id: objPortal["planAreaId"],
        department_code: objPortal["departmentCode"],
        district_code: objPortal["districtCode"],
        plan_area_name: objPortal["planAreaName"],
        plan_area_rfc: objPortal["planAreaRfc"]
      })
    }
    //テーブル表示用データ取得(手動検索)
    if (target === 'searchGetReceptionBook') {
      return await getReceptionBook({
        mode: "getReceptionBook",
        status_task_update_date: statusTaskUpdateDate,
        status_id: statusId,
        pic_type: picType,
        pic_name: picName,
        task_id: taskId,
        plan_area_id: planAreaId,
        department_code: departmentCode,
        district_code: districtCode,
        plan_area_name: planAreaName,
        plan_area_rfc: planAreaRfc
      })
    }
  };

  //localstorageからプルダウン値取得
  const setPullDownList = () => {
    const codeMasterObj = localStorage.getItem("codeMaster");
    let checkCodeMaster = false;
    let checkStaffMater = false;
    let checkDeparment = false;
    let checkPlanArea = false;
    if (codeMasterObj !== null) {
      const jsonCodeMasterObj = JSON.parse(codeMasterObj);
      const statusNames = jsonCodeMasterObj.status_name;
      statusNames.map((e: any) => {
        if (!statusList.some(t => t.value === e.code_value)) {
          statusList.push({
            label: e.code_display,
            value: e.code_value
          })
        }
      })
      const picTypes = jsonCodeMasterObj.pic_type;
      picTypes.map((e: any) => {
        if (!picTypeList.some(t => t.value === e.code_value)) {
          picTypeList.push({
            label: e.code_display,
            value: e.code_value
          })
        }
      })
      const taskNames = jsonCodeMasterObj.task_name_id;
      taskNames.map((e: any) => {
        if (!requestTaskList.some(t => t.value === e.code_value)) {
          requestTaskList.push({
            label: e.code_display,
            value: e.code_value
          })
        }
      })
      checkCodeMaster = true;
    }
    const staffMasterObj = localStorage.getItem("staffMaster");
    if (staffMasterObj !== null) {
      const jsonStaffMasterObj = JSON.parse(staffMasterObj);
      jsonStaffMasterObj.map((e: any) => {
        if (!picNameList.some(t => t.value === e.staff_number)) {
          picNameList.push({
            label: e.staff_name,
            value: e.staff_number
          })
        }
        if (!planAreaRfcList.some(t => t.value === e.staff_number) && e.job_category_code_oa === "360") {
          planAreaRfcList.push({
            label: e.staff_name,
            value: e.staff_number
          })
        }
      })
      checkStaffMater = true;
    }
    const departmentMasterObj = localStorage.getItem("departmentMaster");
    if (departmentMasterObj !== null) {
      const jsonDepartmentMasterObj = JSON.parse(departmentMasterObj);
      jsonDepartmentMasterObj.map((e: any) => {
        if (!departmentList.some(t => t.value === e.department_code_oa)) {
          departmentList.push({
            label: e.department_name_oa,
            value: e.department_code_oa
          })
        }
        if (!districtList.some(t => t.value === e.district_code_oa)) {
          districtList.push({
            label: e.district_name_oa,
            value: e.district_code_oa
          })
        }
      })
      checkDeparment = true;
    }
    const planAreaListObj = localStorage.getItem("planAreaList");
    if (planAreaListObj !== null) {
      const jsonPlanAreaListObj = JSON.parse(planAreaListObj);
      jsonPlanAreaListObj.map((e: any) => {
        if (!planAreaIdList.includes(e.id)) {
          planAreaIdList.push(e.id);
        }
      })
      checkPlanArea = true;
    }
    //全てのプルダウンがセット出来たら検索ボタン活性化
    if(checkCodeMaster && checkStaffMater && checkDeparment && checkPlanArea){
      setDisabledSearch(false);
    }
  };
  
  //localStorage更新毎実行関数
  useEffect(()=>{
    //各検索欄プルダウンセット
    setPullDownList();
    //ユーザー別ハンドリング
    setUserInfo();
  },[objPortal.localStorageFlag]);

  //自動検索処理関数Ref
  const callbackRef = useRef<() => void>(batchSearch);
  useEffect(() => {
    // 新しいcallbackをrefに格納
    callbackRef.current = batchSearch; 
  }, [batchSearch]);

  useEffect(() => {
    //localstorageからプルダウン値セット
    setPullDownList();
    //新規クロージャ作成(10秒毎)
    const tick = () => { callbackRef.current() } 
    const id = setInterval(tick, 10000);
    //コンポーネントアンマウント時インターバル処理削除
    return () => { clearInterval(id); };
  }, []);

  //ユーザー別ハンドリング関数
  const setUserInfo = () => {
    let userDepartmentValue:string[] = [];
    let userDistrictValue:string[] = [];
    if (JSON.parse(user.userData || 'null') !== null) {
      const userData = JSON.parse(user.userData || 'null');
      setUserType(userData?.user_type ?? "");
      //GMの場合
      if(userData?.user_type === "z005"){
        userDepartmentValue.push(userData?.department_code_OA);
        setDepartmentCode(userDepartmentValue);
        departmentList.map((e:any)=>{
          if(e.value === userData?.department_code_OA){
            setDepartmentName(e.label);
          }
        })
      //RFC or RMの場合
      } else if (userData?.user_type === "z002" || userData?.user_type === "z003"){
        userDepartmentValue.push(userData?.top_department_code_OA);
        userDistrictValue.push(userData?.department_code_OA);
        setDepartmentCode(userDepartmentValue);
        setDistrictCode(userDistrictValue);
        departmentList.map((e:any)=>{
          if(e.value === userData?.top_department_code_OA){
            setDepartmentName(e.label);
          }
        })
        districtList.map((e:any)=>{
          if(e.value === userData?.department_code_OA){
            setDistrictName(e.label);
          }
        })
      //リクルートリサーチ or その他職種の場合
      } else if(userData?.user_type === "z001" || userData?.user_type === "z004"){
        setDepartmentCode([]);
        setDistrictCode([]);
      }
      //テーブルオブジェクトの設定
      let newVisibleColumns = columns.map((col) => col.field);

      //RFCの場合は非表示カラムを設定
      if(userData?.user_type === "z003"){
        newVisibleColumns = newVisibleColumns.filter(col => col !== 'elapsedTime');
        newVisibleColumns = newVisibleColumns.filter(col => col !== 'type');
        newVisibleColumns = newVisibleColumns.filter(col => col !== 'assembly');
        newVisibleColumns = newVisibleColumns.filter(col => col !== 'branch');
        newVisibleColumns = newVisibleColumns.filter(col => col !== 'pic_name');
      }
      setVisibleColumns(newVisibleColumns);
    }
  };

  //ユーザー取得タイミングによる画面制御
  useEffect(() => {
    //ユーザー別ハンドリング
    setUserInfo();
  }, [user]);

  //候補値No登録(手動登録)
  const onRegist = () => {
    const params = {
      mode: "regist",
      endPoint: "/location/Reception/v1/regist",
      query: {
        plan_area_id: registPlanAreaId,
      }
    };
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
      try {
        await apiRequest(params);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
        //モーダルクローズ
        handleRegistModalClose();
      } catch (e) {
        console.log(e);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
        //モーダルクローズ
        handleRegistModalClose();
      }
    })();
  };

  //候補地名設定(手動登録)
  useEffect(() => {
    if (registPlanAreaId === null) {
      setRegistPlanAreaName("");
    }
    if (window.localStorage) {
      let data = localStorage.getItem('planAreaList');
      if (data !== null) {
        let array = JSON.parse(data);
        array.map((e: any) => {
          if (e.id === registPlanAreaId) {
            setRegistPlanAreaName(e.plan_area_name);
          }
        })
      }
    }
  }, [registPlanAreaId]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} sx={{ height: "48px" }} />
      </Grid>
      <Grid container direction={'row'} sx={{ position: "fixed" }}>
        <Grid item sx={{ width: props.width + 56, height: props.height, overflow: "scroll" }}>

          {/* コンテンツボディ */}
          <Box sx={{ position: "relative", bgcolor: "#F8FAF8", padding: "20px" }}>
            <Grid container spacing={2} rowSpacing={2} columnSpacing={2}>

              {/* 入力フォーム部分 */}
              <Grid item xs={5}>
                <Paper sx={{ position: "relative" }}>
                  <Table sx={{ tableLayout: "fixed" }}>
                    <TableBody>
                      <TableRow>
                        <InputFormCell sx={{ width: "90px" }}>依頼受付日</InputFormCell>
                        <InputFormCell>
                          <TextField
                            value={statusTaskUpdateDate}
                            onChange={(e) => { setStatusTaskUpdateDate(e.target.value) }}
                            type="date"
                            style={{ padding: "0px 5px" }}>
                          </TextField>
                        </InputFormCell>
                        <InputFormCell sx={{ width: "90px" }}>ステータス</InputFormCell>
                        <InputFormCell>
                          <Autocomplete
                            multiple
                            size="small"
                            id="AutocompStatus"
                            limitTags={1}
                            options={statusList}
                            value={statusValueList}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, v) => option.value === v.value}
                            onChange={(e, val, sit) => { if (sit === "removeOption") { document.getElementById("AutocompStatus")?.focus(); } setStatusValueList(val) }}
                            renderInput={(params) => (<TextField onBlur={() => { setSearchParams("status") }} {...params} variant="outlined"></TextField>)}
                          ></Autocomplete>
                        </InputFormCell>
                      </TableRow>
                      <TableRow>
                        <InputFormCell>部</InputFormCell>
                        <InputFormCell>
                        {userType !== "z002" && userType !== "z003" && userType !== "z005" ?
                          <Autocomplete
                            multiple
                            size="small"
                            id="AutocompDepartment"
                            limitTags={1}
                            options={departmentList}
                            value={departmentValueList}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, v) => option.value === v.value}
                            onChange={(e, val, sit) => { if (sit === "removeOption") { document.getElementById("AutocompDepartment")?.focus(); } setDepartmentValueList(val) }}
                            renderInput={(params) => (<TextField onBlur={() => { setSearchParams("department") }} {...params} variant="outlined"></TextField>)}
                          ></Autocomplete>
                          :
                          <TextField disabled value={departmentName} variant="outlined"></TextField>
                        }
                        </InputFormCell>
                        <InputFormCell>担当依頼</InputFormCell>
                        <InputFormCell>
                          <Autocomplete
                            multiple
                            size="small"
                            id="AutocompPicType"
                            limitTags={1}
                            options={picTypeList}
                            value={picTypeValueList}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, v) => option.value === v.value}
                            onChange={(e, val, sit) => { if (sit === "removeOption") { document.getElementById("AutocompPicType")?.focus(); } setPicTypeValueList(val) }}
                            renderInput={(params) => (<TextField onBlur={() => { setSearchParams("picType") }} {...params} variant="outlined"></TextField>)}
                          ></Autocomplete>
                        </InputFormCell>
                      </TableRow>
                      <TableRow>
                        <InputFormCell>地区</InputFormCell>
                        <InputFormCell>
                        {userType !== "z002" && userType !== "z003" ?
                          <Autocomplete
                            multiple
                            size="small"
                            id="AutocompDistrict"
                            limitTags={1}
                            options={districtList}
                            value={districtValueList}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, v) => option.value === v.value}
                            onChange={(e, val, sit) => { if (sit === "removeOption") { document.getElementById("AutocompDistrict")?.focus(); } setDistrictValueList(val) }}
                            renderInput={(params) => (<TextField onBlur={() => { setSearchParams("district") }} {...params} variant="outlined"></TextField>)}
                          ></Autocomplete>
                          :
                          <TextField disabled value={districtName} variant="outlined"></TextField>
                        }
                          
                        </InputFormCell>
                        <InputFormCell>依頼担当者</InputFormCell>
                        <InputFormCell>
                          <Autocomplete
                            multiple
                            size="small"
                            id="AutocompPicName"
                            limitTags={1}
                            options={picNameList}
                            value={picNameValueList}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, v) => option.value === v.value}
                            disabled={userType === "z002" || userType === "z003" || userType === "z004" || userType === "z005"}
                            onChange={(e, val, sit) => { if (sit === "removeOption") { document.getElementById("AutocompPicName")?.focus(); } setPicNameValueList(val) }}
                            renderInput={(params) => (<TextField onBlur={() => { setSearchParams("picName") }} {...params} variant="outlined"></TextField>)}
                          ></Autocomplete>
                        </InputFormCell>
                      </TableRow>
                      <TableRow>
                        <InputFormCell>候補地No</InputFormCell>
                        <InputFormCell>
                          <Autocomplete
                            size="small"
                            options={planAreaIdList}
                            value={planAreaId}
                            getOptionLabel={(planAreaIdList) => planAreaIdList}
                            onChange={(e, val) => { setPlanAreaId(val === null ? "" : val) }}
                            renderInput={(params) => (<TextField {...params} variant="outlined"></TextField>)}
                          ></Autocomplete>
                        </InputFormCell>
                        <InputFormCell>依頼タスク</InputFormCell>
                        <InputFormCell>
                          <Autocomplete
                            multiple
                            size="small"
                            id="AutocompTask"
                            limitTags={1}
                            options={requestTaskList}
                            value={taskValueList}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, v) => option.value === v.value}
                            onChange={(e, val, sit) => { if (sit === "removeOption") { document.getElementById("AutocompTask")?.focus(); } setTaskValueList(val) }}
                            renderInput={(params) => (<TextField onBlur={() => { setSearchParams("task") }} {...params} variant="outlined" ></TextField>)}
                          ></Autocomplete>
                        </InputFormCell>
                      </TableRow>
                      <TableRow>
                        <InputFormCell>候補地名</InputFormCell>
                        <InputFormCell>
                          <TextField
                            value={planAreaName}
                            onChange={(e) => { setPlanAreaName(e.target.value) }}>
                          </TextField>
                        </InputFormCell>
                        <InputFormCell>RFC</InputFormCell>
                        <InputFormCell>
                          <Autocomplete
                            multiple
                            size="small"
                            id="AutocompPlanAreaRfc"
                            limitTags={1}
                            options={planAreaRfcList}
                            value={rfcValueList}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, v) => option.value === v.value}
                            onChange={(e, val, sit) => { if (sit === "removeOption") { document.getElementById("AutocompPlanAreaRfc")?.focus(); } setRfcValueList(val) }}
                            renderInput={(params) => (<TextField onBlur={() => { setSearchParams("planAreaRfc") }} {...params} variant="outlined"></TextField>)}
                          ></Autocomplete>
                        </InputFormCell>
                      </TableRow>
                      <TableRow>
                        <InputFormCell></InputFormCell>
                        <InputFormCell></InputFormCell>
                        <InputFormCell></InputFormCell>
                        <InputFormCell><BgColorButton disabled={disabledSearch} onClick={() => { handleSerch() }}>検索</BgColorButton></InputFormCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
              <Grid item xs={1}></Grid>

              {/* ボタン部分 */}
              <Grid item xs={6}>
                <Grid container spacing={4}>
                  {userType !== "z002" && userType !== "z003" &&
                   userType !== "z004" && userType !== "z005" ?
                   <>
                    <Grid item xs={3}><NormalButton onClick={handleGisImportModalOpen}>GIS取込</NormalButton></Grid>
                    <Grid item xs={3}><NormalButton onClick={() => { window.open(`${window.location.origin}/Portal/CircularHistory`) }}>全回覧履歴</NormalButton></Grid>
                   </>
                   :
                   <>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}></Grid>
                   </>
                  }
                  <Grid item xs={3}><NormalButton onClick={() => { window.open(`${window.location.origin}/PortalReport`) }}>レポート</NormalButton></Grid>
                  <Grid item xs={3}><NormalButton onClick={() => { window.open(process.env.REACT_APP_RECRUIT_VIEW) }}>リクルートビュー</NormalButton></Grid>
                </Grid>
              </Grid>

              {/* モーダルボタン */}
              {userType !== "z001" && userType !== "z002" && userType !== "z003" && 
               userType !== "z004" && userType !== "z005" &&
              <Grid item xs={12}>
                <BgColorButton onClick={handleRgistModalOpen}>手動登録</BgColorButton>
              </Grid>
              }

              {/* テーブル部分 */}
              <Grid item xs={12}>
                <Box sx={{ width: "100%" }}>
                  <DataGrid
                    columns={columns.filter(col => visibleColumns.includes(col.field))}
                    rows={rows}
                    sx={styles.dataGrid}
                    density="compact"
                    autoHeight
                    onRowSelectionModelChange={handleTableModalOpen}
                    components={{ Toolbar: costomToolBar }}
                    loading={dataGridLoading}
                    getRowClassName={(params: GridRowParams) => {
                      if (params.row.elapsedFlag === "1") {
                        return 'active-elapsed-row'
                      }
                      return ''
                    }}
                  />
                </Box>
              </Grid>

              {/* モーダル部分(手動登録) */}
              <Dialog open={registModalOpen} onClose={handleRegistModalClose} sx={styles.modalStyle}>
                <DialogTitle sx={{ textAlign: "center" }}>手動登録</DialogTitle>
                <Box sx={{ padding: "15px" }}>
                  <Grid container spacing={2} sx={{ marginBottom: "10px" }}>
                    <Grid item xs={12}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                          <Typography>候補地No</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Autocomplete
                            size="small"
                            value={registPlanAreaId}
                            onChange={(e, newVal) => { setRegistPlanAreaId(newVal === null ? "" : newVal) }}
                            options={planAreaIdList}
                            getOptionLabel={(planAreaIdList) => planAreaIdList}
                            renderInput={(params) => (<TextField {...params} variant="outlined" ></TextField>)}
                          ></Autocomplete>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "10px" }}>
                    <Grid item xs={12}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                          <Typography>候補地名</Typography>
                        </Grid>
                        <Grid item xs={8} sx={styles.disabledTextField}>
                          <TextField value={registPlanAreaName} variant="outlined" disabled sx={styles.disabledTextField}></TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "10px" }}>
                    <Grid item xs={12}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                          <Typography>部</Typography>
                        </Grid>
                        <Grid item xs={8} sx={styles.disabledTextField}>
                          <TextField variant="outlined" disabled sx={styles.disabledTextField}></TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "10px" }}>
                    <Grid item xs={12}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                          <Typography>地区</Typography>
                        </Grid>
                        <Grid item xs={8} sx={styles.disabledTextField}>
                          <TextField variant="outlined" disabled sx={styles.disabledTextField}></TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "10px" }}>
                    <Grid item xs={12}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={6}>
                          <CancelButton onClick={() => { handleRegistModalClose() }}>キャンセル</CancelButton>
                        </Grid>
                        <Grid item xs={6} sx={styles.disabledTextField}>
                          <BgColorButton sx={{ float: "right" }} onClick={() => { onRegist() }}>登録</BgColorButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Dialog>

              {/* モーダル部分(調査書枝選択画面) */}
              <ResearchBranchModal props={{ modalOpen: researchBranchOpen, setModalOpen: setResearchBranchOpen, planAreaId: selectPlanAreaId, obj: selectDataObj }} />

              {/* モーダル部分(メール作成画面) */}
              <MailCreateModal props={{ modalOpen: mailModalOpen, setModalOpen: setMailModalOpen, obj: selectMailObj, user: user.userData }} />

              {/* モーダル部分(テーブルレコード) */}
              <Dialog open={tableModalOpen} onClose={handleTableModalClose}>
                <DialogTitle>選択してください</DialogTitle>
                <List>
                  <ListItem>
                    <ModalButton 
                      onClick={handleResearchBranchOpen}>
                      調査書
                    </ModalButton>
                  </ListItem>
                  <ListItem>
                    <ModalButton 
                      onClick={() => {window.open(`/RequestComment?planAreaId=${selectPlanAreaId}&branch=${selectBranch}`)}} 
                      disabled={userType === "z002" || userType === "z003" || userType === "z005"}>
                      依頼/コメント
                    </ModalButton>
                  </ListItem>
                  <ListItem>
                    <ModalButton 
                      onClick={handleMailModalOpen} 
                      disabled={userType === "z002" || userType === "z003" || userType === "z004" || userType === "z005"}>
                      メール送信
                    </ModalButton>
                  </ListItem>
                </List>
              </Dialog>

              {/* モーダル部分(GIS取込) */}
              <GisImportModal props={{ open: gisImportModalOpen, handleModal: closeGisImportModal }} />

            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SecondaryListItems;

