import { useState, useEffect } from 'react';
import { useUserContext } from '../../user-provider';
import { useUtilityContext } from '../../utility-provider';
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { apiRequest } from "../../api/ApiCall";
import { locationBaseData } from '../components/LocationBaseFunc';
import {
  setLongPageSurveyType,
  setUsePlanAareaId,
  setStorePlanAreaId,
  setLocationBase,
  setInitLocationBase,
  setLongPageParamObj,
  setSaveStatus,
  setDuplicateStatus
} from '../../features/longPageSlice';
import { setObjAppHeader } from '../../features/appHeaderSlice';
import { setObjCover } from '../../features/coverSlice';
import {
  coverData,
  upsertCoverParam
} from '../subPage/Cover/CoverFunction';
import {
  setObjStoreOpeningConditions,
  setInitObjStoreOpeningConditions,
  setStoreOpeningConditionsShowAddItems,
  setStoreOpeningConditionsLongTextShow,
  setShowPreviousJob,
  setMonthHeder,
  setSixMonthPassed,
} from '../../features/storeOpeningConditionsSlice';
import {
  storeOpeningConditionsData,
  parseDateString,
  getTwelveMonths,
  upsertStoreOpeningConditionsParam
} from '../subPage/StoreOpeningConditions/StoreOpeningConditionsFunction';
import {
  setTradeAreaTaxAudit,
  setTradeAreaCar5MinMp,
  setTradeAreaInitMpData,
  setTradeAreaMpData
} from '../../features/tradeAreaSlice';
import {
  taxAudit,
  car5MinMp,
  initMpData,
  mpData,
  upsertTradeAreaParam
} from '../subPage/TradeArea/TradeAreaFunction';
import { setObjDonation } from '../../features/donationSlice';
import { donationData } from '../subPage/Donation/DonationFunction';
import { setConflict, setMarketCut } from '../../features/conflictSlice';
import { setTrafficVolume } from '../../features/trafficVolumeSlice';
import { setN15AddMarket } from '../../features/n15AddMarketSlice';
import { setObjImpact } from '../../features/impactSlice';
import {
  setObjLocationTraffic5mcut,
  setObjLocationTraffic5mcutStation,
  setObjLocationTraffic5mcutSchool
} from '../../features/locationTraffic5mcutSlice';
import { setObjLocationWalk5minCircle } from '../../features/locationWalk5minCircleSlice';
import { setObjPicture } from '../../features/pictureSlice';
import { upsertlocationBaseParam } from '../components/LocationBaseFunc'; 
import {
  initPhoto,
  upsertPhotoParam
} from '../subPage/Pictuer/PhotoFunction';
import { upsertDonationParam } from '../subPage/Donation/DonationFunction';
import {
  locationTrafficTotal,
  upsertLocationTraffic5mcutParam
} from '../subPage/TrafficVolume/TrafficVolumeFunction';
import { upsertLocationConflictMarketCut } from '../subPage/Conflict/AddConflictFunction';
import {
  setObjDrawing,
  setDrawingLongTextShow
} from '../../features/drawingSlice';
import {
  drawingData,
  upsertDrawingParam
 } from '../subPage/Drawing/DrawingFunction';
import {
  setPermissionFunc,
  groupSearchFunc
} from '../components/PermissionFunc';
import {
  Typography,
  Button,
  Grid,
  styled,
  Box,
  FormControl,
  Select,
  MenuItem,
  TextField,
  SelectChangeEvent,
  createTheme,
  Autocomplete,
} from '@mui/material';
import { LOCAL_CONSTANT } from '../Const'
import { getLongPageData, getDuplicate } from '../../data/DataManage';

import SalesForecastSummaryMainElementList from './SalesForecastSummaryMainElementList'
import AddionalResearch from './AdditionalResearch';
import OtherDocument from './OtherDocument';
import RRinternalUse from './RRinternalUse';
import Cover from './Cover';
import StoreOpeningConditions from './StoreOpeningConditions';
import Picture from './Picture';
import TradeArea from './TradeArea';
import Donation from './Donation';
import TrafficVolume from './TrafficVolume';
import Conflict from './Conflict';
import Impact from './Impact';
import Drawing from './Drawing';
import Map from './Map';
import RequestTaskModal from '../components/RequestTaskModal';
import Menu from './Menu';
import { longpage_status_check } from '../subPage/LongPage/LongPageFunc'
import AlertMessageDialog from '../components/AlertMessageDialog';
import RRconfirmModal from '../components/RRconfirmModal';
import { showHideFunc } from '../components/PermissionFunc';

export interface resultDialogProps {
  props: {
    width: number,
    height: number
  }
}

export interface locationTypeList {
  uuid: string,
  create_date: string,
  update_date: string,
  delete_flag: string,
  common_item_flag: string,
  item_name_display: string,
  item_name_value: string,
  code_display: string,
  code_value: string,
  sort_column: string
};

//スタイル
const ColorButton = styled(Button)({
  backgroundColor: "#00CC5B",
  color: "white",
  '&: hover':{
    backgroundColor: '#29e17a'
  }
});



const LongPage: React.FC<resultDialogProps> = ({ props }) => {
  const user = useUserContext();
  const utilityCtx = useUtilityContext();
  const dispatch = useAppDispatch();

  //ヘッダーオブジェクト
  const objAppHeader = useAppSelector((state) => state.appHeader);

  //URLパラメータ
  const [searchParams] = useSearchParams();
  const planAreaId = searchParams.get("planAreaId");
  const branch = searchParams.get("branch");
  const objLongPageParam = useAppSelector((state) => state.longPage.param);
  const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
  const objInitLocationBase = useAppSelector((state) => state.longPage.initLocationBase);
  const objStorePlanAreaId = useAppSelector((state) => state.longPage.storePlanAreaId);
  //調査種別プルダウンの値を取得
  const selectedSurveyType = useAppSelector((state) => state.longPage.surveyType);
  //既存店ボタン この既存店NOが調査書で使用する候補地IDを取得
  const usePlanAareaId = useAppSelector((state) => state.longPage.usePlanAareaId);
  // 通行量
  const objTrafficVolume = useAppSelector((state) => state.trafficVolume);
  //モーダル開閉フラグ(依頼ボタン)
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  //出店条件
  const objLongTextShow = useAppSelector((state) => state.storeOpeningConditions.showLongText);
  //複製ボタン押下後、複製完了のステータス
  const duplicateStatus = useAppSelector((state) => state.longPage.duplicateStatus);
  //ロングページ保存用ステータス
  const objLongPageSaveStatus = useAppSelector((state) => state.longPage.saveStatus);
  // 表紙
  const objCover = useAppSelector((state)=> state.cover.obj);
  // 出店条件
  const objStoreOpeningConditions = useAppSelector((state) => state.storeOpeningConditions.obj);
  //出店条件 初期値
  const objInitStoreOpeningConditions = useAppSelector((state) => state.storeOpeningConditions.initObj);
  // 商圏
  const listTradeArea = useAppSelector((state) => state.tradeArea.mpDataList);
  // 現況写真
  const objPicture = useAppSelector((state) => state.picture);
  // 寄付き
  const objDonation = useAppSelector((state) => state.donation.obj);
  // 通行量(5m外歩行者TR算入確認)
  const objLocationTraffic5mcut = useAppSelector((state) => state.locationTraffic5mcut.obj);
  // N15追加商圏
  const objN15AddMarket = useAppSelector((state) => state.n15AddMarket);
  // 通行量 その他 駅出入口の名称と距離
  const objStation = useAppSelector((state) => state.locationTraffic5mcut.stationObj);
  // 通行量 その他 大学短大の名称と距離 
  const objSchool = useAppSelector((state) => state.locationTraffic5mcut.schoolObj);
  // 通行量(5m外歩行者TR算入確認)
  const objConflictMarketCut = useAppSelector((state) => state.conflict.market_cut);
  // 図面
  const objDrawing = useAppSelector((state) => state.drawing.obj);

  //調査種別プルダウンリスト表示用
  const [locationTypeList, setLocationTypeList] = useState<locationTypeList[]>();

  //調査種別プルダウンのvalueを格納
  const handleSelectChange = (e: SelectChangeEvent<typeof selectedSurveyType>) => {
    dispatch(setLongPageSurveyType(e.target.value));
    dispatch(setLocationBase({...objLocationBase, location_type:e.target.value}));
    //更新フラグ
    if (!objLongPageSaveStatus.LongPage) dispatch(setSaveStatus({...objLongPageSaveStatus,['LongPage']: true}));
  };

  const [load, setLoad] = useState("standBy")
  //ユーザー種別を格納  
  const [userType, setUserType] = useState('');

  const [alertLabel, setAlertLabel] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertMessageDialogOpen, setAlertMessageDialogOpen] = useState<boolean>(false);
  const [hasButton, setHasButton] = useState<boolean>(false);

  //リサーチ確認中モーダル
  const [RRconfModalOpen, setRRconfModalOpen] = useState(false);

  //検索パラメータ用(既存店一覧)
  const [existingSejStoreAll, setExistingSejStoreAll] = useState<string[]>([]);
  //S店情報
  const [sStoreInfo, setSstoreInfo] = useState<any | null>("");
  //改造前/タイプ変更前情報
  const [typeBeforeInfo, setTypeBeforeInfo] = useState<any | null>("");

  const closeMessageDialog = () => {
		setAlertLabel("");
		setAlertMessage("");
    setHasButton(false);
    setAlertMessageDialogOpen(false);
	};


  //モーダルオープン(依頼ボタン)、保存処理
  const handleRequestModalOpen = () => {
    const params: any = [];
    // 調査書基本
    if (objLongPageSaveStatus.LongPage){
      params.push(upsertlocationBaseParam(objLocationBase, objLongPageParam.planAreaId, objLongPageParam.branch))
    }
    // 表紙
    if (objLongPageSaveStatus.Cover) {
      params.push(upsertCoverParam(objCover, objLongPageParam.planAreaId, objLongPageParam.branch))
    }
    // 出店条件
    if (objLongPageSaveStatus.StoreOpeningConditions) {
      params.push(upsertStoreOpeningConditionsParam(objStoreOpeningConditions, objLongPageParam.planAreaId, objLongPageParam.branch))
    }
    // 商圏
    if (objLongPageSaveStatus.TradeArea) {
      params.push(upsertTradeAreaParam(listTradeArea, objLongPageParam.planAreaId, objLongPageParam.branch, objLocationBase.location_excel_sequence))
    }
    // 現況写真
    if (objLongPageSaveStatus.Picture) {
      params.push(upsertPhotoParam(objPicture, objLongPageParam.planAreaId, objLongPageParam.branch))
    }
    // 寄付き
    if (objLongPageSaveStatus.Donation) {
      params.push(upsertDonationParam(objDonation, objLongPageParam.planAreaId, objLongPageParam.branch))
    }
    // 通行量
    if (objLongPageSaveStatus.TrafficVolume) {
      params.push(upsertLocationTraffic5mcutParam(objLocationTraffic5mcut, objLongPageParam.planAreaId, objLongPageParam.branch, objStation, objSchool))
    }
    // 競合　商圏カット
    if (objLongPageSaveStatus.ConflictMarketCut) {
      params.push(upsertLocationConflictMarketCut(objConflictMarketCut, objLongPageParam.planAreaId, objLongPageParam.branch))
    }
    // 図面
    if (objLongPageSaveStatus.Drawing) {
      params.push(upsertDrawingParam(objDrawing, objLongPageParam.planAreaId, objLongPageParam.branch))
    }
    if (params.length > 0) {
      if (utilityCtx.showSpinner) {
          utilityCtx.showSpinner();
      }
      updateRegist(params).then(() => {
          dispatch(
            setSaveStatus({
              ...objLongPageSaveStatus,
              LongPage: false,
              Cover: false,
              StoreOpeningConditions: false,
              TradeArea: false,
              Picture: false,
              Donation: false,
              TrafficVolume: false,
              Conflict: false,
              Impact: false,
              Map: false,
              Drawing: false
            })
          )
          if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
          //モーダルオープン
          setRequestModalOpen(true);
          }
      }).catch(e => {
          if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
          }
      })
    } else {
      //モーダルオープン
      setRequestModalOpen(true);
    }
  };

  const updateRegist = (params: any) => {
    return new Promise((resolve, reject) => {
      params.map((param: any) => {
        (async () => {
          try {
            await apiRequest(param);
            resolve(true);
          } catch (e) {
            console.log(e);
            reject();
          }
        })();
      })
    })
  }

  //調査種別プルダウン選択時、各画面を非表示 falseが非表示
  //調査種別プルダウンによってh3タグのフォントサイズをtrueのとき19px falseのとき0px 
  const h3CommonStyle = (variableValue: boolean) => ({
    marginLeft: "25px",
    fontSize: variableValue ? "19px" : "0px",
  });
  const SalesForecastHidden: boolean = (
    !(objLocationBase.task_id !== 'B010'
    && objLocationBase.task_id !== 'B011'
    && objLocationBase.task_id !== 'B102'
    && objLocationBase.task_id !== 'B103'
    && objLocationBase.task_id !== 'B012'
    && objLocationBase.task_id !== 'C006'
    && objLocationBase.task_id !== 'C007'
    && objLocationBase.task_id !== 'C008')
  );
  //影響度画面を非表示
  const impactHidden: boolean = (
    selectedSurveyType !== "02"  //セットバック(改造後)を選択
    && selectedSurveyType !== "03"  //立地評価(タイプ変更後)を選択
    && selectedSurveyType !== "05"  //S&B(S店)を選択
    && selectedSurveyType !== "06"  //セットバック(改造前・接道変化無し)を選択
    && selectedSurveyType !== "07"  //セットバック(改造前・接道変化有り)を選択
    && selectedSurveyType !== "08"  //立地評価(タイプ変更前・店格改善無し)を選択
    && selectedSurveyType !== "09"  //立地評価(タイプ変更前・店格改善有り)を選択
  );
  //現況写真画面を非表示
  const pictureHidden: boolean = (
    selectedSurveyType !== "06"  //セットバック(改造前・接道変化無し)を選択
    && selectedSurveyType !== "07"  //セットバック(改造前・接道変化有り)を選択
    && selectedSurveyType !== "08"  //立地評価(タイプ変更前・店格改善無し)を選択
    && selectedSurveyType !== "09"  //立地評価(タイプ変更前・店格改善有り)を選択
  );
  //商圏画面を非表示
  const tradeAreaHidden: boolean = (
    selectedSurveyType !== "06"  //セットバック(改造前・接道変化無し)を選択
    && selectedSurveyType !== "07"  //セットバック(改造前・接道変化有り)を選択                     
    && selectedSurveyType !== "08"  //立地評価(タイプ変更前・店格改善無し)を選択
    && selectedSurveyType !== "09"  //立地評価(タイプ変更前・店格改善有り)を選択
  );
  //通行量画面を非表示
  const trafficVolumeHidden: boolean = (
    selectedSurveyType !== "06"  //セットバック(改造前・接道変化無し)を選択
    && selectedSurveyType !== "08"  //立地評価(タイプ変更前・店格改善無し)を選択
    && selectedSurveyType !== "09"  //立地評価(タイプ変更前・店格改善有り)を選択
  );
  //競合画面を非表示
  const conflictHidden: boolean = (
    selectedSurveyType !== "06"  //セットバック(改造前・接道変化無し)を選択
    && selectedSurveyType !== "08"  //立地評価(タイプ変更前・店格改善無し)を選択
    && selectedSurveyType !== "09"  //立地評価(タイプ変更前・店格改善有り)を選択
  );
  //地図画面を非表示
  const mapHidden: boolean = (
    selectedSurveyType !== "06"  //セットバック(改造前・接道変化無し)を選択
    && selectedSurveyType !== "08"  //立地評価(タイプ変更前・店格改善無し)を選択
    && selectedSurveyType !== "09"  //立地評価(タイプ変更前・店格改善有り)を選択
  );
  //寄付き画面を非表示
  const donationHidden: boolean = (selectedSurveyType !== "08");  //立地評価(タイプ変更前・店格改善無し)を選択

  //調査種別プルダウンリスト項目設定
  const getlocationTypeList = () => {
    const masterObj = localStorage.getItem("codeMaster");
    if (masterObj !== null) {
      const jsonMasterObj = JSON.parse(masterObj);
      setLocationTypeList(jsonMasterObj.location_type);
    }
  };

  const callApi = async (target: string) => {
    const param: any = []
    if (target === 'getLocationBase') {
      let searchPlanAreaId = planAreaId
      param.push({
        mode: 'getLocationBase',
        plan_area_id: planAreaId,
        branch: branch,
      })
      const jsonUserData = JSON.parse(user.userData);
      let manageCitiesFlg = true
      if (jsonUserData.administrator_flag === "1" || jsonUserData.manage_cities?.includes("ALL")) {
        manageCitiesFlg = false;
      }
      const manage_steps = ['0', '99']
      // 複製店舗は既存店のためSTEP8は対象外
      if (planAreaId && (!planAreaId.startsWith("X") && !planAreaId.startsWith("Y"))) {
        manage_steps.push('8')
      }
      let stepCnt = jsonUserData.manage_steps.filter(
        (val: any) => manage_steps.includes(val) === false
      );
      let managePlansOutsideCities = jsonUserData.manage_plans_outside_cities;
      if (!managePlansOutsideCities.includes("")) {
        managePlansOutsideCities.push("");
      }
      if (planAreaId && (planAreaId.startsWith("X") || planAreaId.startsWith("Y"))) {
        searchPlanAreaId = objStorePlanAreaId
      }

      param.push({
        mode: 'planAreaSearchById',
        id: searchPlanAreaId,
        manageCitiesFlg: manageCitiesFlg,
        manageCities: jsonUserData.manage_cities.join(','),
        manageStep: stepCnt.join(','),
        managePlansOutsideCities: managePlansOutsideCities.join(','),
      })
    } else if (target === 'getLongPageData') {
      if (objLocationBase.child_location !== "") {
        param.push({
          mode: 'getExistingSejStoreUsePlanAreaId',
          plan_area_id: planAreaId,
          branch: branch,
          child_location: objLocationBase.child_location
        })
      }
      param.push({
          mode: 'getLocationCover',
          plan_area_id: planAreaId,
          branch: branch,
      })
      param.push({
          mode: 'getLocationStoreOpeningConditions',
          plan_area_id: planAreaId,
          branch: branch,
      })
      if (objAppHeader.citycode !== "") {
        param.push({
          mode: 'getNationalTaxAudit',
          city_code: objAppHeader.citycode,
        })
      }
      if (objLocationBase.location_excel_sequence !== '0') {
        param.push({
          mode: 'getCar5MinMp',
          plan_area_id: planAreaId,
          excel_sequence: objLocationBase.location_excel_sequence,
        })
      }
      if (objLocationBase.location_excel_sequence !== '0') {
        param.push({
            mode: 'getLocationTradeAreaMpData',
            plan_area_id: planAreaId,
            branch: branch,
            excel_sequence: objLocationBase.location_excel_sequence,
        })
      }
      if (objLocationBase.location_excel_sequence !== '0') {
        param.push({
            mode: 'getGradientWalk5MinMpData',
            plan_area_id: planAreaId,
            excel_sequence: objLocationBase.location_excel_sequence,
        })
      }
      param.push({
          mode: 'getLocationPictuer',
          plan_area_id: planAreaId,
          branch: branch,
      })
      param.push({
          mode: 'getLocationDonation',
          plan_area_id: planAreaId,
          branch: branch,
      })
      param.push({
          mode: 'getLocationConflict',
          plan_area_id: planAreaId,
          branch: branch,
      })
      param.push({
          mode: 'getLocationConflictMarketCut',
          plan_area_id: planAreaId,
          branch: branch,
      })
      param.push({
          mode: 'getLocationWalk5minCircle',
          plan_area_id: planAreaId,
      })
      param.push({
          mode: 'getLocationTraffic',
          plan_area_id: planAreaId,
          branch: branch,
      })
      param.push({
          mode: 'getN15AddMarket',
          plan_area_id: planAreaId,
      })
      param.push({
          mode: 'getLocationImpact',
          plan_area_id: planAreaId,
          branch: branch,
      })
      param.push({
          mode: 'getLocationTraffic5mcut',
          plan_area_id: planAreaId,
          branch: branch,
      })
      if (objLocationBase.location_excel_sequence !== '0') {
        param.push({
            mode: 'getTrafficOtherStation',
            plan_area_id: planAreaId,
            excel_sequence: objLocationBase.location_excel_sequence,
        })
      }
      if (objLocationBase.location_excel_sequence !== '0') {
        param.push({
            mode: 'getTrafficOtherSchool',
            plan_area_id: planAreaId,
            excel_sequence: objLocationBase.location_excel_sequence,
        })
      }
      param.push({
          mode: 'getLocationDrawing',
          plan_area_id: planAreaId,
          branch: branch,
      })
    } else if (target === 'getLocationLinkByUsePlanAreaId') {
      param.push({
        mode: 'getLocationLinkByUsePlanAreaId',
        use_plan_area_id: planAreaId
      })
    }
    return await getLongPageData(param);
  };

  //複製ボタン押下後、既存店ボタン use_plan_area_idと出店条件を取得する
  const callApiDuplicate = async (target: string) => {
    const param: any = []
    if (target === 'getDuplicate') {
      param.push({
        mode: 'getLocationBase',
        plan_area_id: planAreaId,
        branch: branch,
      })
      if (objLocationBase.child_location !== "") {
        param.push({
          mode: 'getExistingSejStoreUsePlanAreaId',
          plan_area_id: planAreaId,
          branch: branch,
          child_location: objLocationBase.child_location
        })
      }
      param.push({
        mode: 'getLocationStoreOpeningConditions',
        plan_area_id: planAreaId,
        branch: branch,
      })
      return await getDuplicate(param);
    }
  }

  //改造前/タイプ変更前情報に設定された既存店のstore_codeを調査書基本テーブルのchild_locationに設定
  const handleSelectTypeBeforeInfo = (e: React.SyntheticEvent, value: string | null) => {
    if (value !== "" && value !== null) {
      const [tenpoName, storeCode] = value.split("：");
      const matchCheck = storeCode.match(/\d+/);
      const registStoreCode:any = matchCheck ? matchCheck[0] : null;
      if (registStoreCode) {
        dispatch(setLocationBase({...objLocationBase, child_location: registStoreCode}));
      }
      if (registStoreCode && tenpoName) {
        //複製前にはS店情報、受付NOが分からないため既存店を選んだ際に仮の値を設定
        //出店条件 既存店名と店NOと受付NOを更新
        dispatch(setObjStoreOpeningConditions({...objStoreOpeningConditions,existingStoreName: tenpoName, storeNo: registStoreCode, receptionNo: '**********'}));
      }
    } else {
      //初期値に戻す
      dispatch(setObjStoreOpeningConditions({
        ...objStoreOpeningConditions,
          existingStoreName: objInitStoreOpeningConditions.existingStoreName,
          storeNo: objInitStoreOpeningConditions.storeNo,
          receptionNo: objInitStoreOpeningConditions.receptionNo}));
      if (objInitLocationBase.child_location === '') {
        setTypeBeforeInfo('');
      } else {
        existingSejStoreAll.map((row:any) => {
          if (row.includes(objInitLocationBase.child_location)) {
            setTypeBeforeInfo(row);
          }
        })
      }
    }
    // 更新フラグ
    if (!objLongPageSaveStatus.LongPage) {
      dispatch(setSaveStatus({...objLongPageSaveStatus,['LongPage']: true,['StoreOpeningConditions']: true}));
    }
  };

  //別タブで調査書画面を開く
  const handleOpenNewLocationClick = (use_plan_area_id:String, branch:String|null) => {
    const baseUrl = window.location.origin;
    const fullUrl = `${baseUrl}/LongPage?planAreaId=${use_plan_area_id}&branch=${branch}`;
    window.open(fullUrl);
  };

  useEffect(() => {
    if ((JSON.parse(user.userData || 'null') !== null) && load === 'standBy') {
      if (planAreaId && (!planAreaId.startsWith("X") && !planAreaId.startsWith("Y"))) {
        setLoad('loadLocationBase');
      }
      if (planAreaId && (planAreaId.startsWith("X") || planAreaId.startsWith("Y"))) {
        // 複製データ
        setLoad('getLocationLinkByUsePlanAreaId');
      }
    }
  }, [user])

  useEffect(() => {
    try {
      if (load === 'loadLocationBase') {
        getlocationTypeList();
          // 候補地ID、枝番保存
        dispatch(
          setLongPageParamObj({
            ...objLongPageParam,
            planAreaId: planAreaId,
            branch: branch
          })
        )
        callApi('getLocationBase').then((res: any) => {
          //ロングページ
          dispatch(setLocationBase(locationBaseData(res.getLocationBase)));
          dispatch(setInitLocationBase(locationBaseData(res.getLocationBase)));  //初期値
          dispatch(setLongPageSurveyType(res.getLocationBase[0].location_type));
          //ヘッダー
          dispatch(setObjAppHeader({
            ...objAppHeader,
            planAreaId: res.planAreaSearchById[0].id,
            planAreaName: res.planAreaSearchById[0].plan_area_name,
            citycode: res.planAreaSearchById[0].citycode,
            centerlat: res.planAreaSearchById[0].centerlat,
            centerlon: res.planAreaSearchById[0].centerlon
          }));
        }).catch((e) => {
          console.log(e);
          setLoad("error")
        }).finally(() => {
          setLoad("loadLongPageData")
        })
      } else if (load === 'loadLongPageData') {
        callApi('getLongPageData').then((res: any) => {
          if (res.getExistingSejStoreUsePlanAreaId?.length > 0) {
            dispatch(setUsePlanAareaId(res.getExistingSejStoreUsePlanAreaId[0].use_plan_area_id)) //既存店ボタン use_plan_area_idを取得
          }
          //表紙
          dispatch(setObjCover(coverData(res.getLocationCover)));
          //出店条件
          dispatch(setObjStoreOpeningConditions(storeOpeningConditionsData(res.getLocationStoreOpeningConditions)));
          dispatch(setInitObjStoreOpeningConditions(storeOpeningConditionsData(res.getLocationStoreOpeningConditions))); //出店条件ページ初期値
          //出店条件画面表示非表示の対応
          // 候補地跡地のプルダウンが「なし」、「自社建替え」、「自社跡地」の場合は前職を非表示
          if (res.getLocationStoreOpeningConditions[0].candidate_location_ruins === "なし") {
            dispatch(setShowPreviousJob(false));
            dispatch(setStoreOpeningConditionsLongTextShow({
              ...objLongTextShow,
              candidateLocationRuins: false,                                                      //候補地跡地のプルダウンの下にロングテキストを非表示
              removalReason: res.getLocationStoreOpeningConditions[0].is_store_name_select_reason //所在地表示以外にて選定にチェックを入れた時　下にロングテキストを表示
            }));
          } else if (res.getLocationStoreOpeningConditions[0].candidate_location_ruins === "自社建替え" || res.getLocationStoreOpeningConditions[0].candidate_location_ruins === "自社跡地") {
            dispatch(setShowPreviousJob(false));
            dispatch(setStoreOpeningConditionsLongTextShow({
              ...objLongTextShow,
              candidateLocationRuins: true,                                                       //候補地跡地のプルダウンの下にロングテキストを表示
              removalReason: res.getLocationStoreOpeningConditions[0].is_store_name_select_reason //所在地表示以外にて選定にチェックを入れた時　下にロングテキストを表示
            }));
            // 候補地跡地のプルダウンが「競合看板替え」、「競合跡地」の場合は前職の「候補地が競合跡地」のみ表示
          } else if (res.getLocationStoreOpeningConditions[0].candidate_location_ruins === "競合看板替え" || res.getLocationStoreOpeningConditions[0].candidate_location_ruins === "競合跡地") {
            dispatch(setShowPreviousJob(true));
            dispatch(setStoreOpeningConditionsLongTextShow({
              ...objLongTextShow,
              candidateLocationRuins: true,                                                       //候補地跡地のプルダウンの下にロングテキストを表示
              removalReason: res.getLocationStoreOpeningConditions[0].is_store_name_select_reason //所在地表示以外にて選定にチェックを入れた時　下にロングテキストを表示
            }));
          }
          //店舗面積が空でなく、かつ入力された値が132未満の場合は 「※店舗面積40坪未満の場合、設備詳細を記入」を表示
          dispatch(setStoreOpeningConditionsShowAddItems(res.getLocationStoreOpeningConditions[0].store_area !== "" && Number(res.getLocationStoreOpeningConditions[0].store_area) < 132));
          //直近確定月のプルダウンの年月によってヘッダーを切り替え
          if (res.getLocationStoreOpeningConditions[0].last_fixed_month !== "") {
            const {year, month} = parseDateString(res.getLocationStoreOpeningConditions[0].last_fixed_month);
            const mothsHeader = getTwelveMonths(year, month);
            //ヘッダー
            dispatch(setMonthHeder(mothsHeader));
            //直近確定月 プルダウン 6か月経過してるか判定するため保存
            dispatch(setSixMonthPassed(res.getLocationStoreOpeningConditions[0].last_fixed_month));
          }
          //商圏
          if (objAppHeader.citycode !== "" && res.getNationalTaxAudit.length > 0) {
            dispatch(setTradeAreaTaxAudit(taxAudit(res.getNationalTaxAudit)));
          }
          if (objLocationBase.location_excel_sequence !== '0' && res.getCar5MinMp.length > 0) {
            dispatch(setTradeAreaCar5MinMp(car5MinMp(res.getCar5MinMp)));
          }
          if (objLocationBase.location_excel_sequence !== '0') {
            // location_excel_04_mp_dataとlocation_excel_05_mp_data2とlocation_trade_areaにデータが存在する場合
            if (res.getLocationTradeAreaMpData.length > 0) {
              dispatch(setTradeAreaInitMpData(initMpData(res.getLocationTradeAreaMpData)));
              dispatch(setTradeAreaMpData(mpData(res.getLocationTradeAreaMpData)));
            // location_excel_04_mp_dataとlocation_excel_05_mp_data2にデータが存在する場合
            } else if (res.getGradientWalk5MinMpData.length > 0) {
              dispatch(setTradeAreaInitMpData(initMpData(res.getGradientWalk5MinMpData)));
              dispatch(setTradeAreaMpData(mpData(res.getGradientWalk5MinMpData)));
            }
          }
          // 現況写真
          dispatch(setObjPicture(initPhoto(res.getLocationPictuer)));
          //寄付き 
          dispatch(setObjDonation(donationData(res.getLocationDonation)));
          //競合 
          dispatch(setConflict(res.getLocationConflict));
          // 商圏カット
          if (res.getLocationConflictMarketCut.length > 0) {
            dispatch(setMarketCut(res.getLocationConflictMarketCut[0]));
          }
          // 徒歩5分商圏円
          dispatch(setObjLocationWalk5minCircle(res.getLocationWalk5minCircle));
          //通行量
          dispatch(setTrafficVolume(locationTrafficTotal(res.getLocationTraffic)));
          dispatch(setN15AddMarket(res.getN15AddMarket));
          if (res.getLocationTraffic5mcut.length > 0) {
            dispatch(setObjLocationTraffic5mcut(res.getLocationTraffic5mcut[0]));
          }
          //通行量その他駅出入口
          if (objLocationBase.location_excel_sequence !== '0' && res.getTrafficOtherStation.length > 0) {
            dispatch(setObjLocationTraffic5mcutStation(res.getTrafficOtherStation[0]));
          }
          //通行量その他大学短大
          if (objLocationBase.location_excel_sequence !== '0' && res.getTrafficOtherSchool.length > 0) {
            dispatch(setObjLocationTraffic5mcutSchool(res.getTrafficOtherSchool[0]));
          }
          //影響度
          dispatch(setObjImpact(res.getLocationImpact));
          //図面
          if (res.getLocationDrawing.length > 0) {
            dispatch(setObjDrawing(drawingData(res.getLocationDrawing)));
            if (res.getLocationDrawing[0].is_other) {
              dispatch(setDrawingLongTextShow(true));
            }
          }
        }).catch((e) => {
          console.log(e);
          setLoad("error")
        }).finally(() => {
          setLoad("fin")
        })
      } else if (load === 'fin') {
        // RFCがタスクグループB、Cを開いたとき、リサーチ確認中モーダルを表示
        if (userType === "z003" && (groupSearchFunc(objLocationBase.task_id) === "B" || groupSearchFunc(objLocationBase.task_id) === "C")) {
          setRRconfModalOpen(true);
        } else {
          // 状況確認
          const check_message = longpage_status_check({
            objLocationBase: objLocationBase,
            objTrafficVolume: objTrafficVolume,
            objN15AddMarket: objN15AddMarket,
          })
          if (check_message !== "") {
            setAlertLabel(LOCAL_CONSTANT.ALERT_MESSAGE.ALERT_LONGCHECK_LABEL)
            setAlertMessage(check_message)
            setHasButton(true)
            setAlertMessageDialogOpen(true)
          }
        }
      } else if (load === 'getLocationLinkByUsePlanAreaId') {
        callApi('getLocationLinkByUsePlanAreaId').then((res: any) => {
          if (res.getLocationLinkByUsePlanAreaId.length > 0) {
            dispatch(setStorePlanAreaId(res.getLocationLinkByUsePlanAreaId[0]['store_plan_area_id']))
            setLoad('loadLocationBase');
          }
        })
      }
    } catch (e) {
      console.log(e);
      setLoad("error")
    }
  }, [load])

  //ユーザー取得タイミングによる画面制御
  useEffect(() => {
    if (JSON.parse(user.userData || 'null') !== null) {
        setUserType(JSON.parse(user.userData || 'null').user_type);
    }
  }, [user]);

  //調査種別の項目制御
  useEffect(() => {
    if (selectedSurveyType ==="01") {         //純増のプルダウン制御  セットバック（改造後）、立地評価（タイプ変更後
      setLocationTypeList(locationTypeList?.filter(surveytype => surveytype.code_value === "01" || surveytype.code_value === "02" || surveytype.code_value === "03"));
    } else if (selectedSurveyType ==="04") {  //S＆B（B店）のプルダウン制御 
      setLocationTypeList(locationTypeList?.filter(surveytype => surveytype.code_value === "04"));
    } else if (selectedSurveyType ==="05") {  //S＆B（S店）のプルダウン制御
      setLocationTypeList(locationTypeList?.filter(surveytype => surveytype.code_value === "05"));
    } else if (selectedSurveyType ==="02") {  //セットバック（改造後）のプルダウン制御  純増、立地評価（タイプ変更後）
      setLocationTypeList(locationTypeList?.filter(surveytype => surveytype.code_value==="02" || surveytype.code_value === "01" || surveytype.code_value === "03"));
    } else if (selectedSurveyType ==="06") {  //セットバック（改造前・接道変化無し）のプルダウン制御  セットバック（改造前・接道変化有り）
      setLocationTypeList(locationTypeList?.filter(surveytype => surveytype.code_value === "06" || surveytype.code_value === "07"));
    } else if (selectedSurveyType ==="07") {  //セットバック（改造前・接道変化有り）のプルダウン制御  セットバック（改造前・接道変化無し）
      setLocationTypeList(locationTypeList?.filter(surveytype => surveytype.code_value === "07" || surveytype.code_value === "06"));
    } else if (selectedSurveyType ==="03") {  //立地評価（タイプ変更後）のプルダウン制御  純増、セットバック（改造後）
      setLocationTypeList(locationTypeList?.filter(surveytype => surveytype.code_value === "03" || surveytype.code_value === "01" || surveytype.code_value === "02"));
    } else if (selectedSurveyType ==="08") {  //立地評価（タイプ変更前・店格改善無し）のプルダウン制御  立地評価（タイプ変更前・店格改善有り
      setLocationTypeList(locationTypeList?.filter(surveytype => surveytype.code_value === "08" || surveytype.code_value === "09"));
    } else if (selectedSurveyType ==="09") {  //立地評価（タイプ変更前・店格改善有りのプルダウン制御  立地評価（タイプ変更前・店格改善無し）
      setLocationTypeList(locationTypeList?.filter(surveytype => surveytype.code_value === "09" || surveytype.code_value === "08"));
    }
  },[selectedSurveyType]);

  //検索パラメータ用(既存店一覧)の表示形式を変更
  useEffect(() => {
    const existingSejStoreAllList:any[] = [];
    const existingSejStoreAllObj: any = localStorage.getItem('existingSejStoreAll');
    if (existingSejStoreAllObj) {
      const jsonExistingSejStoreAllObj = JSON.parse(existingSejStoreAllObj);
      jsonExistingSejStoreAllObj.map((row:any) => {
        existingSejStoreAllList.push(row.tenpo_name + "：（店No" + row.store_code + "）");
      })
      setExistingSejStoreAll(existingSejStoreAllList);
    }
  },[]);

  useEffect(() => {
    // child_locationに設定されていたらＳ店情報に画面を表示
    if (objLocationBase.child_location !== "" && selectedSurveyType === "04") {
      existingSejStoreAll.map((row:any) => {
        if (row.includes(objLocationBase.child_location)) {
          setSstoreInfo(row);
        }
      })
    }
    // child_locationに設定されていたら改造前/タイプ変更前情報に画面表示
    if (objLocationBase.child_location !== "" && (selectedSurveyType === "02" || selectedSurveyType === "03")) {
      existingSejStoreAll.map((row:any) => {
        if (row.includes(objLocationBase.child_location)) {
          setTypeBeforeInfo(row);
        }
      })
    }
  },[objLocationBase.child_location])

  useEffect(() => {
    //S店情報の内容を出店条件に反映
    if (selectedSurveyType === "04" && sStoreInfo !== "" && sStoreInfo !== null) {
      const [tenpoName, storeCode] = sStoreInfo.split("：");
      const matchCheck = storeCode.match(/\d+/);
      const registStoreCode:any = matchCheck ? matchCheck[0] : null;
      if (registStoreCode && tenpoName) {
        //出店条件 既存店名と店NOを更新
        dispatch(setObjStoreOpeningConditions({...objStoreOpeningConditions,existingStoreName: tenpoName, storeNo: registStoreCode}));
      }
    }
  },[
      sStoreInfo,
      objStoreOpeningConditions.existingStoreName,
      objStoreOpeningConditions.storeNo
    ])

  //複製ボタン押下後、既存店ボタン use_plan_area_idを取得、出店条件 S店情報の受付NOを取得
  useEffect(() => {
    if (duplicateStatus) {
      try {
        callApiDuplicate('getDuplicate').then((res: any) => {
          //初期値をDBの値を更新
          dispatch(setInitLocationBase(locationBaseData(res.getLocationBase)));
          //既存店ボタン use_plan_area_idを取得
          if (res.getExistingSejStoreUsePlanAreaId?.length > 0) {
            dispatch(setUsePlanAareaId(res.getExistingSejStoreUsePlanAreaId[0].use_plan_area_id));
          }
          //出店条件 S店情報 受付NOをアスタリスクなど仮の値からDBの値へ更新
          dispatch(setObjStoreOpeningConditions({...objStoreOpeningConditions, receptionNo: res.getLocationStoreOpeningConditions[0].reception_no}));
          //出店条件の初期値をDBの更新
          dispatch(setInitObjStoreOpeningConditions(storeOpeningConditionsData(res.getLocationStoreOpeningConditions)));
        })
      } catch (e) {
        console.log(e);
      }
      //複製完了後 複製のステータスフラグを更新
      dispatch(setDuplicateStatus(false));
    }
  },[duplicateStatus]);

  return (
    <Box>
      {/* リサーチ確認中モーダル */}
      <RRconfirmModal props={{
        RRconfModalOpen:RRconfModalOpen,
        setRRconfModalOpen: setRRconfModalOpen
      }}
      />
      <AlertMessageDialog props={{
        open:alertMessageDialogOpen,
        handleModal:closeMessageDialog,
        label:alertLabel,
        body:alertMessage,
        hasButton:hasButton,
        buttonBody:LOCAL_CONSTANT.LABEL.CLOSE
      }}
      />
      <Grid container>
        <Grid item xs={12} sx={{ height: "48px" }} />
      </Grid>
      <Grid container direction={'row'} sx={{ position: "fixed" }}>
        <Grid item sx={{ width: "55px", height: props.height }} />
        <Grid item id="contentInner" sx={{ width: props.width, height: props.height, overflowY: "scroll", backgroundColor: "#F8FAF8" }}>
          <Menu props={{ planAreaId: planAreaId, branch: branch, excelSequence: objLocationBase.location_excel_sequence }} />
          <Box sx={{ position: "relative", zIndex: 10, width: '97%' }}>
          
          {showHideFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST, "page") && 
            <>
              <h3 id="SalesForecastSummaryMainElementList" style={{marginLeft: "25px", fontSize: "19px"}}>予測集計表等</h3>
              <SalesForecastSummaryMainElementList props={{}} />
            </>
          }

            <h3 id="AdditionalResearch" style={h3CommonStyle(true)}>追加調査</h3>
            <AddionalResearch props={{}} />

            <h3 id="OtherDocument" style={h3CommonStyle(true)}>その他資料</h3>
            <OtherDocument props={{}} />

            {/* ユーザーがRRの場合にRR内部用画面を表示 */}
            <h3 id="RRinternalUse" style={h3CommonStyle(userType==='z001')}>RR内部用</h3>
            {userType==='z001' && (<Box><RRinternalUse/></Box>)}

            <h3 id="Cover" style={h3CommonStyle(true)}>表紙</h3>

            {/* 調査種別プルダウン */}
            <Grid container spacing={2} maxWidth="lg" alignItems="center">
              <Grid item xs={4}>
                <Typography sx={{ marginLeft: "30px" }}>調査種別</Typography>
              </Grid>
              <Grid item xs={8}>
                <FormControl fullWidth>
                  <Select
                    onChange={handleSelectChange}
                    value={selectedSurveyType}
                    disabled={setPermissionFunc(userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.COVER,"surveyType")}
                  >
                    {locationTypeList !== undefined &&
                      locationTypeList.map((val, i) => (
                        <MenuItem key={i} value={val.code_value}>{val.code_display}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {/* 調査種別プルダウン選択して各テキストフィールド表示 */}
            {/* 調査種別がS&B(B店)の場合のみ表示 */}
            {(selectedSurveyType === "04") && (
              <Grid container spacing={2} maxWidth="lg" alignItems="center" sx={{ marginTop: "2px" }}>
                <Grid item xs={4} >
                  <Typography sx={{ marginLeft: "30px" }}>Ｓ店情報</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Autocomplete
                      options={existingSejStoreAll}
                      disabled={true}
                      value={sStoreInfo}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (<TextField {...params} variant="outlined"></TextField>)}
                    ></Autocomplete>
                </Grid>
              </Grid>
            )}
            {/* 調査種別がS&B(S店)の場合のみ表示 */}
            {(selectedSurveyType === "05") && (
              <Grid container spacing={2} maxWidth="lg" alignItems="center" sx={{ marginTop: "2px" }}>
                <Grid item xs={4} >
                  <Typography sx={{ marginLeft: "30px" }}>Ｂ店情報</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    variant="filled"
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
            )}
            {/* 調査種別がセットバック(改造後)、立地評価(タイプ変更後)の場合のみ表示 */}
            {(selectedSurveyType === "02" ||
              selectedSurveyType === "03") && (
                <Grid container spacing={2} maxWidth="lg" alignItems="center" sx={{ marginTop: "2px" }}>
                  <Grid item xs={4} >
                    <Typography sx={{ marginLeft: "30px" }}>改造前/タイプ変更前情報</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Autocomplete
                      options={existingSejStoreAll}
                      value={typeBeforeInfo}
                      getOptionLabel={(option) => option}
                      onChange={handleSelectTypeBeforeInfo}
                      renderInput={(params) => (<TextField {...params} variant="outlined"></TextField>)}
                    ></Autocomplete>
                  </Grid>
                </Grid>
              )}
            {/* 調査種別が
                    セットバック(改造前・接道変化無し)、セットバック(改造前・接道変化有り)、
                    立地評価(タイプ変更前・店格改善無し)、立地評価(タイプ変更前・店格改善有り)の場合のみ表示 */}
            {(selectedSurveyType === "06" || selectedSurveyType === "07" ||
              selectedSurveyType === "08" || selectedSurveyType === "09"
            ) && (
                <Grid container spacing={2} maxWidth="lg" alignItems="center" sx={{ marginTop: "2px" }}>
                  <Grid item xs={4} >
                    <Typography sx={{ marginLeft: "30px" }}>改造後/タイプ変更後情報</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      variant="filled"
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>
              )}

            {/* 既存店ボタン */}
            {/* 調査種別が「セットバック（改造後）」「立地評価（タイプ変更後）」「 S＆B（B店） 」の時に表示 */}
            {(selectedSurveyType === "02" || selectedSurveyType === "03" || selectedSurveyType === "04") &&
              <Grid container spacing={2} maxWidth="lg" alignItems="center" sx={{ marginTop: "2px" }}>
                <Grid item xs={4} >
                </Grid>
                <Grid item xs={8}>
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    disabled={usePlanAareaId === "" ? true : false}
                    onClick={() => handleOpenNewLocationClick(usePlanAareaId,branch)}
                  >既存店</Button>
                  </Grid>
              </Grid>
            }

            <Cover props={{}} />
            <h3 id="StoreOpeningConditions" style={h3CommonStyle(true)}>{LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS}</h3>
            <StoreOpeningConditions props={{}} />

            {/* 調査種別プルダウンの値によって商圏画面を非表示 */}
            <h3 id="TradeArea" style={h3CommonStyle(tradeAreaHidden)}>{LOCAL_CONSTANT.NAVI.TRADE_AREA}</h3>
            {tradeAreaHidden && (<Box><TradeArea props={{}} /></Box>)}

            {/* 調査種別プルダウンの値によって写真画面を非表示 */}
            <h3 id="Picture" style={h3CommonStyle(pictureHidden)}>{LOCAL_CONSTANT.NAVI.PICTURE}</h3>
            {pictureHidden && (<Box><Picture props={{}} /></Box>)}

            {/* 調査種別プルダウンの値によって寄付き画面を非表示 */}
            <h3 id="Donation" style={h3CommonStyle(donationHidden)}>{LOCAL_CONSTANT.NAVI.DONATION}</h3>
            {donationHidden && (<Box><Donation props={{}} /></Box>)}

            {/* 調査種別プルダウンの値によって通行量画面を非表示 */}
            <h3 id="TrafficVolume" style={h3CommonStyle(trafficVolumeHidden)}>{LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME}</h3>
            {trafficVolumeHidden && (<Box><TrafficVolume props={{}} /></Box>)}

            {/* 調査種別プルダウンの値によって競合画面を非表示 */}
            <h3 id="Conflict" style={h3CommonStyle(conflictHidden)}>{LOCAL_CONSTANT.NAVI.CONFLICT}</h3>
            {conflictHidden && (<Box><Conflict props={{}} /></Box>)}

            {/* 調査種別プルダウンの値によって影響度画面を非表示 */}
            <h3 id="Impact" style={h3CommonStyle(impactHidden)}>影響度</h3>
            {impactHidden && (<Box><Impact props={{}} /></Box>)}

            {/* 調査種別プルダウンの値によって地図画面を非表示 */}
            <h3 id="Map" style={h3CommonStyle(mapHidden)}>{LOCAL_CONSTANT.NAVI.MAP}</h3>
            {mapHidden && (<Box><Map props={{}} /></Box>)}
            
            {/* 図面 */}
            <h3 id="Drawing" style={h3CommonStyle(true)}>{LOCAL_CONSTANT.NAVI.DRAWING}</h3>
            <Drawing props={{}} />

            {/* 依頼ボタン */}
            {showHideFunc(userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.LONGPAGE, 'request_button') &&
            <ColorButton sx={{position: "fixed", right: "2.5%", bottom: "7%", zIndex: 1000}} onClick={handleRequestModalOpen}>依頼</ColorButton>
            }

            {/* 各種依頼モーダル */}
            <RequestTaskModal props={{ modalOpen: requestModalOpen, setModalOpen: setRequestModalOpen, plan_area_id: planAreaId, branch: branch }} />

          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LongPage;
